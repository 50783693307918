import { ANIMATE_ORDERS, RESET_ANIMATE_ORDERS } from './types'

/* State Shape
{

}
*/

const initialState = {
  animateOrders: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ANIMATE_ORDERS: {
      const { msg } = action.payload

      return {
        ...state,
        animateOrders: [...state.animateOrders, msg.id]
      }
    }

    case RESET_ANIMATE_ORDERS: {
      return {
        ...state,
        animateOrders: []
      }
    }

    default:
      return state
  }
}
