import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './state/store'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

const passwordPresent = () => document.cookie.match(/password=\d+/)

if (passwordPresent()) {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
  setInterval(() => {
    if (!passwordPresent()) document.location.reload()
  }, 1000)
} else {
  ReactDOM.render(<Login />, document.getElementById('root'))
}

function checkCookie(val) {
  const date = new Date()
  const m = ('0' + date.getMinutes()).slice(-2)
  let h = ('0' + date.getHours()).slice(-2)
  const password = (h + m)
    .split('')
    .reverse()
    .join('')
  console.log(val, password)
  return val === password
}

function Login() {
  function validateInput(val) {
    if (val === '1488') {
      // unlimited access
      document.cookie = `password=${val}; path=/; expires=Wed, 31 Jul 2020 12:20:36 GMT`
      document.location.reload()
    }

    if (checkCookie(val)) {
      let date = new Date(new Date().getTime() + 30 * 60 * 1000)
      document.cookie = `password=${val}; path=/; expires=${date.toUTCString()}`
      document.location.reload()
    }
  }
  return (
    <div>
      Багодарим за участие в тесте. <br />
      Пожалуйста, введите номер теста. <br />
      <input type='tel' onChange={val => validateInput(val.target.value)} />
    </div>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// temporary fix for drag not registering after scroll
document.body.addEventListener(
  'touchmove',
  function(event) {
    event.preventDefault()
  },
  false
)

// Disable scroll restoration for SPA
window.history.scrollRestoration = 'manual'
