import React, { useState, useEffect, useRef } from 'react'
import S from './Item.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { removeNewOrder } from '../../state/orders'
import { getAnimateOrders } from '../../state/ui'
import { TweenMax, Draggable, Ease, Power2, Back } from 'gsap/all'
import { findId, money, orderBtnText } from '../../utils'
import OrderBtn from '../shared/OrderBtn'
import CatIcons from '../shared/CatIcons'
import CookTimer from '../shared/CookTimer'
import cx from 'classnames'
import {useTranslation} from "../../hooks/useTranslation";

export default function Item({ state, setOptions, options, dish, id, type, customStyle, timer = true, orderId = 0 }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // TODO: convert to redux selector
  const shouldAnimate = useSelector(state => state.ui.animateOrders.includes(orderId))
  const [opn, setOpn] = useState(false)
  const itemRef = useRef()
  const dishName = useRef()
  const optionsRef = useRef()
  const wrapperRef = useRef()
  const iconRef = useRef()

  const animOpn = (wrapperRef, iconRef) => {
    TweenMax.to(wrapperRef.current, 0.25, {
      height: '80px',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      boxShadow: '0px -1px 0px rgba(138, 147, 151, 0.5), 0px 1px 0px rgba(138, 147, 151, 0.5)',
      ease: Back.easeOut.config(1.1)
    })
    TweenMax.to(iconRef.current, 0.25, {
      height: '80px',
      ease: Back.easeOut.config(1.1)
    })
  }
  const animCls = (wrapperRef, iconRef) => {
    TweenMax.to(wrapperRef.current, 0.25, {
      height: '40px',
      backgroundColor: 'unset',
      boxShadow: '0px -1px 0px rgba(138, 147, 151, 0), 0px 1px 0px rgba(138, 147, 151, 0)',
      ease: Back.easeOut.config(1.1)
    })
    TweenMax.to(iconRef.current, 0.25, {
      height: '40px',
      ease: Back.easeOut.config(1.1)
    })
  }
  useEffect(() => {
    if (state) {
      if (options !== dish.id && opn) {
        animCls(wrapperRef, iconRef)
        setOpn(false)
      }
    }
  }, [options])

  React.useLayoutEffect(() => {
    if (dish && type === 'new') {
      itemRef.current.myClassToggle = c => itemRef.current.classList.toggle(S[c])
    }

    if (!dish) return
    const ellipsizeTextBox = el => {
      el = el.current
      var wordArray = el.innerHTML.split(' ')
      while (el.scrollHeight > 24) {
        wordArray.pop()
        el.innerHTML = wordArray.join(' ') + '...'
      }
    }
    ellipsizeTextBox(dishName)
  }, [dish])

  if (dish) {
    return (
      <div ref={wrapperRef} className={S.itemWrapper} style={customStyle}>
        <div
          className={cx(S.item, S[type], { animatePayment: shouldAnimate })}
          onClick={() => {
            if (state) {
              if (options) {
                if (options === dish.id) {
                  animCls(wrapperRef, iconRef)
                  setOptions(false)
                  setOpn(false)
                } else {
                  animOpn(wrapperRef, iconRef)
                  setOptions(dish.id)
                  setOpn(true)
                }
              }
              if (!options) {
                animOpn(wrapperRef, iconRef)
                setOptions(dish.id)
                setOpn(true)
              }
            }
          }}
          ref={itemRef}
        >
          <div className={S.icon} ref={iconRef}>
            <CatIcons icon={dish.catIcon} size='20' color='white' />
          </div>
          <div className={S.title} ref={dishName}>
            {t(dish.name)}
          </div>
          {type === 'new' ? (
            <div className={S.price}> {money(dish.price)} </div>
          ) : type === 'cooking' ? (
            timer ? (
              <CookTimer
                start={Math.round(new Date().getTime() / 1000)}
                time={1 * 60} // {Math.round((Math.random() * 10) / 2) * 60}
              />
            ) : (
              ''
            )
          ) : (
            ''
          )}

          {opn ? (
            <div className={S.options} ref={optionsRef}>
              {id && (
                <OrderBtn
                  clickHandler={() => dispatch(removeNewOrder(id))}
                  customStyle={{
                    color: '#FF005C',
                    backgroundColor: 'rgba(0,0,0,0)',
                    shadow: 'none',
                    width: '100%'
                  }}
                >
                  Удалить
                </OrderBtn>
              )}
              <OrderBtn
                clickHandler={() => {
                  window.small = wrapperRef.current
                  window.dish = dish
                  document.getElementsByClassName('OrderDrawerShader')[0].click()
                  document.getElementById('fakeID').click()
                }}
                customStyle={{
                  color: '#FF005C',
                  backgroundColor: 'rgba(0,0,0,0)',
                  shadow: 'none',
                  width: '100%'
                }}
              >
                Открыть
              </OrderBtn>
            </div>
          ) : null}

          <div />
        </div>
      </div>
    )
  } else {
    return null
  }
}
