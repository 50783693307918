import { combineReducers } from 'redux'
import loader from './loader'
import venues from './venues'
import favorites from './favorites'
import popular from './popular'
import location from './location'
import user from './user'
import menu from './menu'
import orders from './orders'
import ui from './ui'

export default combineReducers({ loader, favorites, popular, location, user, menu, orders, ui, venues })
