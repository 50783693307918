import {
  FETCH_PAID_ORDERS,
  ADD_NEW_ORDER,
  REMOVE_NEW_ORDER,
  PROCESS_ORDER_FROM_WEBSOCKET,
  SET_PAYMENT_TYPE,
  SET_TAKEOUT
} from './types'

/* State Shape
{
  new: [],
  paid: [],
  takeout: bool,
  paymentType: (string | undefined)
}
*/

const initialState = {
  new: [],
  paid: [],
  paymentType: undefined,
  takeout: undefined
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAID_ORDERS: {
      const { orders } = action.payload
      return {
        ...state,
        paid: orders
      }
    }

    case ADD_NEW_ORDER: {
      const { dish } = action.payload
      const newOrder = {
        tempId: Date.now(),
        userId: 1,
        dishId: dish.id,
        price: dish.price,
        takeout: state.takeout
      }

      return {
        ...state,
        new: [newOrder, ...state.new]
      }
    }

    case SET_PAYMENT_TYPE: {
      const { type } = action.payload

      return {
        ...state,
        paymentType: type
      }
    }

    case REMOVE_NEW_ORDER: {
      const { tempId } = action.payload

      return {
        ...state,
        new: state.new.filter(x => x.tempId !== tempId)
      }
    }

    case PROCESS_ORDER_FROM_WEBSOCKET: {
      const { msg } = action.payload
      const paidUpdated = state.paid.filter(x => x.id !== msg.id)

      return {
        ...state,
        new: state.new.filter(x => x.tempId !== msg.tempId),
        paid: [msg, ...paidUpdated]
      }
    }

    default:
      return state
  }
}

// Process received orders from websocket
// useEffect(() => {
//   setOrders(state => {
//     const paidUpdated = state.paid.filter(o => o.id !== receivedMsg.id)

//     return {
//       ...state,
//       new: state.new.filter(o => o.tempId !== receivedMsg.tempId),
//       paid: receivedMsg ? [receivedMsg, ...paidUpdated] : state.paid
//     }
//   })
// }, [receivedMsg])
