import React, { useState, useEffect, useRef } from 'react'
import './FlatMenu.css'
import { useSelector, useDispatch } from 'react-redux'
import { getFavorites } from '../state/favorites'
import { getPopular } from '../state/popular'
import { getDishById } from '../state/menu'
import { addNewOrder } from '../state/orders'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import DishCard from './DishCard'
import CatIcons from './shared/CatIcons'
import { TweenMax, Draggable, Ease } from 'gsap/all'
import { money, animateOrder } from '../utils'
import cx from 'classnames'

import FavIcon from './shared/FavIcon'
import {useTranslation} from "../hooks/useTranslation";

export default function FlatMenu({ ...other }) {
  const favorites = useSelector(getFavorites)
  const popular = useSelector(getPopular)

  useEffect(() => {
    let asd = setInterval(() => {
      if (document.querySelectorAll('.flatcard-front')) return
      ;[...document.querySelectorAll('.flatcard-front')].map(el => {
        if (el._gsTransform.x > 0 && !el.classList.contains('dragged') && !TweenMax.isTweening(el)) {
          console.log(el, 'returning stuck card to 0')
          TweenMax.to(el, 1, { x: 0 })
        }
      })
    }, 2000)
    return () => {
      clearInterval(asd)
    }
  }, [])

  return (
    <div
      style={{
        whiteSpace: 'normal',
        overflow: 'hidden',
        boxShadow: '0px 20px 36px rgba(0, 0, 0, 0.08)',
        borderRadius: '30px',
        padding: '16px 16px',
        marginBottom: '16px',
        backgroundColor: 'white'
      }}
    >
      <div>
        <Tabs>
          <TabList>
            <Tab>Популярное</Tab>
            <Tab>Избранное</Tab>
          </TabList>

          <TabPanel>
            {popular.map(fav => (
              <TextCard key={fav} {...{ fav, ...other }} />
            ))}
          </TabPanel>
          <TabPanel>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                height: '100%',
                backgroundColor: '#fff'
              }}
            >
              {favorites.map(fav => (
                <TextCard key={fav} {...{ fav, ...other }} />
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

function TextCard({ fav, limitScroll }) {
  const dispatch = useDispatch()
  const dish = useSelector(getDishById)(fav)

  const { t } = useTranslation()
  const boxref = useRef()
  const frontref = useRef()
  const underref = useRef()
  const iconref = useRef()
  const [triggered, setTriggered] = useState(false)
  const [dragActive, setDragActive] = useState(false)
  // animation parameters, time in ms:
  const landingduration = 800
  const flipduration = 400
  const flipdelay = 100
  const triggerpoint = 80 // px

  const dishName = useRef()

  useEffect(() => {
    if (dish) {
      const [box, front, under, icon] = [boxref, frontref, underref, iconref].map(r => r.current)
      Draggable.create(front, {
        type: 'x',
        // minimumMovement: 2,
        dragClickables: true,
        allowContextMenu: true,
        zIndexBoost: false,
        bounds: { minX: 0, maxX: dish.stoplist ? 15 : 300 },
        onDragStart: e => {
          setDragActive(true) // sometimes click propagates before mouseup, we must stop that
          front.classList.add('dragged')
        },
        onDrag: e => {
          if (front._gsTransform.x > triggerpoint) {
            setTriggered(true)
          } else {
            setTriggered(false)
          }
        },
        onDragEnd: function(e) {
          setTimeout(() => setDragActive(false), 100) // stop unwanted click propagation
          if (front._gsTransform.x < triggerpoint) {
            setTimeout(() => {
              TweenMax.to(front, 1, { x: 0, ease: Ease.easeOut })
              setTimeout(e => front.classList.remove('dragged'), 700)
            }, 100)
          } else {
            dispatch(addNewOrder(dish))
            front.classList.remove('dragged')
            animateFlatOrder(box, front, under, icon)
          }
        }
      })
      // hint swipe gesture
      let cards = document.getElementsByClassName('flatcard-front')
      const duration = 250
      const stagger = 50
      const num = 7
      if (cards[6] === front) {
        let pauseSwipeHint = false
        const observer = new IntersectionObserver(e => {
          let dragged = document.getElementsByClassName('dragged')[0]
          if (e[0].isIntersecting && !pauseSwipeHint && !(dragged && dragged._gsTransform.x > 1)) {
            pauseSwipeHint = true
            setTimeout(() => {
              pauseSwipeHint = false
            }, 20000)
            for (let i = 0; i < num; i++) {
              setTimeout(() => {
                if (cards[i]) cards[i].classList.add('dragged')
                TweenMax.to(cards[i], duration / 1000, {
                  x: (i + 1) * 1.3,
                  ease: Ease.easeOut
                })
                setTimeout(() => {
                  setTimeout(() => (cards[i] ? cards[i].classList.remove('dragged') : 0), duration * 3)
                  TweenMax.to(cards[i], (duration / 1000) * 4, {
                    x: 0,
                    ease: Ease.easeOut
                  })
                }, duration)
              }, (i + 1) * stagger)
              setTimeout(() => {
                if (cards[i + num]) cards[i + num].classList.add('dragged')
                TweenMax.to(cards[i + num], duration / 1000, {
                  x: (num - i) * 1.3,
                  ease: Ease.easeOut
                })
                setTimeout(() => {
                  setTimeout(() => (cards[i + num] ? cards[i + num].classList.remove('dragged') : 0), duration * 3)
                  TweenMax.to(cards[i + num], (duration / 1000) * 4, {
                    x: 0,
                    ease: Ease.easeOut
                  })
                }, duration)
              }, (i + num) * stagger)
            }
          }
        })
        observer.observe(front)
      }

      const ellipsizeTextBox = el => {
        el = el.current
        var wordArray = el.innerHTML.split(' ')
        while (el.scrollHeight > 41) {
          wordArray.pop()
          el.innerHTML = wordArray.join(' ') + '...'
        }
      }
      ellipsizeTextBox(dishName)
    }
  }, [dish])

  function animateFlatOrder(box, front, under, icon) {
    animateOrder(icon)

    // get ready for flip
    box.classList.toggle('rearm')
    under.classList.toggle('rearm')
    TweenMax.set(front, {
      x: 0
    })
    setTimeout(() => {
      // start flipping animation
      box.classList.toggle('rearm')
      setTimeout(() => {
        // end of the flip, reset everything
        under.classList.toggle('rearm')
        setTriggered(false)
      }, flipduration)
    }, flipdelay)
  }

  if (dish) {
    return (
      <DishCard {...{ dish, dragActive, limitScroll }}>
        <div
          className={cx('flatcard-box', dish.stoplist ? 'stoplist' : null)}
          ref={boxref}
          style={{ transition: `${flipduration / 1000}s` }}
        >
          <div className='flatcard-front' ref={frontref}>
            <div className='flatcard-flex'>
              <FavIcon dishId={dish.id} style={{ top: '8px', right: '6px' }} />

              <span className='flatcard-icon' ref={iconref}>
                <CatIcons icon={dish.catIcon} color={dish.stoplist ? '#c1c1c1' : null} />
              </span>
              <span className='flatcard-name' ref={dishName}>
                {t(dish.name)}
              </span>
              <span className='flatcard-price'>{money(dish.price)}</span>
            </div>
          </div>
          <div className={`flatcard-under ${triggered ? 'triggered' : ''}`} ref={underref}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M21.75 10.5H23.25C23.664 10.5 24 10.1655 24 9.75C24 9.3345 23.664 9 23.25 9H22.2765C20.9115 4.803 17.142 1.869 12.75 1.5615V0.75C12.75 0.336 12.414 0 12 0C11.586 0 11.25 0.336 11.25 0.75V1.563C6.858 1.8705 3.0885 4.8045 1.7235 9H0.75C0.336 9 0 9.336 0 9.75C0 10.164 0.336 10.5 0.75 10.5H2.25H21.75ZM21.75 12H23.25C23.664 12 24 12.336 24 12.75V23.25C24 23.664 23.664 24 23.25 24H21.75C21.336 24 21 23.664 21 23.25V12.75C21 12.336 21.336 12 21.75 12ZM3.6315 13.2242L9.1635 17.5262C9.558 17.8337 10.044 18.0002 10.5435 18.0002H15.75C13.6785 18.0002 12 16.3217 12 14.2502C12 13.8362 12.336 13.5002 12.75 13.5002H19.5V18.0002V22.5002H9.516C8.682 22.5002 7.872 22.2227 7.2135 21.7097L0.8685 16.7747C-0.112499 16.0127 -0.288 14.5982 0.473999 13.6187C1.236 12.6362 2.6535 12.4607 3.6315 13.2242Z'
                fill='white'
              />
            </svg>
            <span>ЗАКАЗАТЬ</span>
          </div>
        </div>
      </DishCard>
    )
  } else {
    return <div>Loading</div>
  }
}
