import React from 'react'
import S from './OrderBtn.module.css'
const OrderBtn = ({ clickHandler, customStyle, children }) => {
  return (
    <>
      <div id={S.bg} style={customStyle} onClick={() => clickHandler()}>
        {children || 'Label'}
      </div>
      <div className={S.spacerR} />
    </>
  )
}

export default OrderBtn
