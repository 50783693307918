import { ADD_POPULAR } from './types'

/* State Shape
[
  int
]
*/

const initialState = [
  65,
  73,
  74,
  75,
  5,
  76,
  77,
  78,
  64,
  59,
  7,
  56,
  2,
  24,
  79,
  66,
  23,
  57,
  14,
  58,
  3,
  63,
  67,
  1,
  11,
  17,
  6,
  62,
  25,
  22,
  60,
  70,
  16,
  72,
  46,
  9
]

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_POPULAR: {
      const { id } = action.payload
      return [...state, id]
    }

    default:
      return state
  }
}
