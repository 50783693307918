import React from 'react'
import { useSelector } from 'react-redux'
import { getDishes } from '../../state/menu'
import { getOrders } from '../../state/orders'
import { ReactComponent as IcoTakeaway } from '../../assets/icons/ico-takeaway.svg'
import Item from './Item'
import { findId } from '../../utils'

export default function Transactions({ mode, state, setOptions, options }) {
  const dishes = useSelector(getDishes)
  const orders = useSelector(getOrders)

  function getTransactions(orders) {
    const reducer = (tr, el) => {
      const { paymentId } = el
      const transaction = tr.find(f => f.paymentId === el.paymentId)
      if (transaction) {
        transaction.dishes.push(el)
      } else {
        tr.push({
          paymentId,
          dishes: [el]
        })
      }
      return tr
    }
    return orders.filter(e => e.takeout).reduce(reducer, [])
  }
  const tr = getTransactions(orders.paid)
  const tr_cooking = tr.filter(t => t.dishes.find(f => f.status !== 'ready'))
  const tr_ready = tr.filter(t => !t.dishes.find(f => f.status === 'cooking'))

  function bgElement(amount, paymentId, ready, orders) {
    const takeoutNum = orders[0] ? orders[0].takeoutNum : 0
    return (
      <span
        style={{
          // background: 'linear-gradient(270deg, #E8EDF0 0%, rgba(248, 248, 248, 0) 100%)',
          width: '100%',
          // height: amount * 40,
          // top: -amount * 40,
          display: 'block',
          position: 'relative'
        }}
        key={paymentId}
      >
        <IcoTakeaway
          style={{
            height: '30px',
            width: '39px',
            right: '26px',
            bottom: '6px',
            fill: 'white',
            position: 'absolute'
          }}
        />
        {ready ? (
          <div style={{ color: '#99A3A8', position: 'absolute', right: '8px', bottom: '12px' }}>
            <span style={{ fontSize: '12px', position: 'relative', top: '-8px' }}>{'#'}</span>
            <span style={{ fontSize: '24px', fontWeight: 'bold' }}>{takeoutNum}</span>
          </div>
        ) : (
          <div style={{ color: '#99A3A8', position: 'absolute', right: '66px', bottom: '24px' }}>
            <span style={{ fontSize: '8px', position: 'relative' }}>{'#'}</span>
            <span style={{ fontSize: '10px', fontWeight: 'bold' }}>{takeoutNum}</span>
          </div>
        )}
      </span>
    )
  }
  let cooking = []
  let ready = []
  tr_cooking.map(
    t =>
      t.dishes.map((d, i) =>
        cooking.push(
          <Item
            customStyle={{
              background: 'linear-gradient(270deg, #E8EDF0 0%, rgba(248, 248, 248, 0) 100%)',
              // borderTop: i === 0 ? '1px dashed #cacfd0' : '',
              // borderTop: i === 0 ? '1px solid' : '',
              // borderImageSource:
              //   i === 0 ? 'linear-gradient(90deg, rgb(255, 255, 255) 20%, rgb(150, 150, 150) 100%)' : '',
              // borderImageSlice: i === 0 ? 1 : ''
              borderBottom: i === t.dishes.length - 1 ? '2px solid white' : undefined
            }}
            orderId={d.id}
            state={state}
            setOptions={setOptions}
            options={options}
            dish={findId(d.dishId, dishes)}
            key={`order-${d.id}`}
            type={'cooking'}
            timer={i === t.dishes.length - 1 ? true : false}
          />
        )
      ) && cooking.push(bgElement(t.dishes.length, t.paymentId, null, t.dishes))
  )
  tr_ready.map(
    t =>
      t.dishes.map((d, i) =>
        ready.push(
          <Item
            customStyle={{
              background: 'linear-gradient(270deg, #E8EDF0 0%, rgba(248, 248, 248, 0) 100%)',
              borderBottom: i === t.dishes.length - 1 ? '2px solid white' : undefined
            }}
            state={state}
            setOptions={setOptions}
            options={options}
            dish={findId(d.dishId, dishes)}
            key={`order-${d.id}`}
            type={'ready'}
          />
        )
      ) && ready.push(bgElement(t.dishes.length, t.paymentId, 1, t.dishes))
  )
  switch (mode) {
    case 'cooking':
      return cooking
    case 'ready':
      return ready
    // no default
  }
}
