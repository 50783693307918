import React, { useRef, useState, useEffect } from 'react'
import S from './RichMenu.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { toggleFavorite, getFavorites } from '../../state/favorites'
import { addNewOrder } from '../../state/orders'
import DishCard from '../DishCard'
import FavButton from '../shared/FavButton'
import DishTimer from '../shared/DishTimer'
import CatIcons from '../shared/CatIcons'
import { money, animateOrder } from '../../utils'
import { TweenMax, Ease, Draggable } from 'gsap/all'
import cx from 'classnames'
import {useTranslation} from "../../hooks/useTranslation";

export const RichMenuCard = React.memo(({ dish, limitScroll, setSubcatSelector }) => {
  const dispatch = useDispatch()
  const favorites = useSelector(getFavorites)

  const { t } = useTranslation()

  const frontref = useRef()
  const orderboxref = useRef()
  const favboxref = useRef()
  const iconref = useRef()
  const [triggered, setTriggered] = useState(false)
  const [dragActive, setDragActive] = useState(false)
  const landingduration = 800
  const triggerpoint = 70 // px
  const orderpoint = 190 // px
  const dishImg = dish.img ? `/${dish.img}` : '/assets/imgs/toast1.jpg'

  useEffect(() => {
    const [front, orderbox, favbox] = [frontref, orderboxref, favboxref].map(r => r.current)
    Draggable.create(front, {
      type: 'x',
      minimumMovement: 0,
      dragClickables: true,
      allowContextMenu: true,
      zIndexBoost: false,
      bounds: { minX: 0, maxX: dish.stoplist ? 15 : 256 },
      liveSnap: function(x) {
        if (Math.abs(x - 128) < 20) return (x - 128) / 2 + 128
        else return x
      },
      onDragStart: e => {
        setSubcatSelector(false)
        front.classList.add(S.dragged)
        orderbox.style.visibility = 'visible'
        favbox.style.visibility = 'visible'
      },
      onDrag: e => {
        orderbox.style.marginLeft = front._gsTransform.x <= 128 ? front._gsTransform.x / 2 + 'px' : '64px'
        front._gsTransform.x > orderpoint && orderbox
          ? orderbox.classList.add(S.triggered)
          : orderbox.classList.remove(S.triggered)
        setDragActive(true) // sometimes click propagates before mouseup, we must stop that
        // if (front._gsTransform.x > 120 && front._gsTransform.x < 136) d.res
        if (front._gsTransform.x > triggerpoint) {
          setTriggered(true)
        } else {
          setTriggered(false)
        }
      },
      onDragEnd: function(e) {
        setTimeout(() => setDragActive(false), 100) // stop unwanted click propagation
        setTimeout(() => {
          orderbox.classList.remove(S.triggered)
          if (front._gsTransform.x < triggerpoint) {
            closeTextCard(front, orderbox)
            front.classList.remove(S.dragged)
          } else if (front._gsTransform.x > orderpoint) {
            front.classList.remove(S.dragged)
            dispatch(addNewOrder(dish))
            setTimeout(() => {
              animateOrder(iconref.current)
            }, 100)
            closeTextCard(front, orderbox)
          } else {
            TweenMax.to(front, 0.3, { x: 128, ease: Ease.easeOut })
            TweenMax.to(orderbox, 0.3, { marginLeft: 64, ease: Ease.easeOut })
          }
        }, 100)
      },
      onClick: e => {
        // e.stopPropagation()
        // e.preventDefault()
        setSubcatSelector(false)
        setDragActive(front._gsTransform.x > 1 ? true : false)
        closeTextCard(front, orderbox)
      }
    })
  }, [])

  function closeTextCard(front, orderbox) {
    TweenMax.to(front, 0.3, { x: 0, ease: Ease.easeOut })
    TweenMax.to(orderbox, 0.3, { marginLeft: 0, ease: Ease.easeOut })
  }

  function favToggle(e) {
    e.stopPropagation()
    dispatch(toggleFavorite(dish.id))
  }

  return (
    <DishCard {...{ dish, dragActive, limitScroll }}>
      <div className={cx(S.richcard, dish.stoplist ? S.stoplist : null)}>
        <div className={S.richcardFront} ref={frontref}>
          <div className={S.richcardImg} style={{ backgroundImage: `url(${dishImg})` }}>
            <FavButton dishId={dish.id} style={{ left: '8px', top: '8px' }} />
            <DishTimer customStyle={{ right: '8px', bottom: '8px' }} />
          </div>
          <div className={S.richcardFlex}>
            <div className={S.richcardIcon} ref={iconref}>
              <CatIcons icon={dish.catIcon} color={dish.stoplist ? '#c1c1c1' : null} />
            </div>
            <div className={S.richcardName}>{t(dish.name)}</div>
            <div className={S.richcardPrice}>
              <div>{money(dish.price)}.</div>
            </div>
          </div>
        </div>
        <div
          ref={orderboxref}
          className={S.richcardOrderbox}
          onClick={e => {
            e.stopPropagation()
            dispatch(addNewOrder(dish))
            orderboxref.current.classList.add(S.triggered)
            setTimeout(() => {
              animateOrder(iconref.current)
            }, 100)
            setTimeout(() => {
              closeTextCard(frontref.current, orderboxref.current)
            }, 300)
            setTimeout(() => {
              orderboxref.current.classList.remove(S.triggered)
            }, 1000)
          }}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M21.75 10.5H23.25C23.664 10.5 24 10.1655 24 9.75C24 9.3345 23.664 9 23.25 9H22.2765C20.9115 4.803 17.142 1.869 12.75 1.5615V0.75C12.75 0.336 12.414 0 12 0C11.586 0 11.25 0.336 11.25 0.75V1.563C6.858 1.8705 3.0885 4.8045 1.7235 9H0.75C0.336 9 0 9.336 0 9.75C0 10.164 0.336 10.5 0.75 10.5H2.25H21.75ZM21.75 12H23.25C23.664 12 24 12.336 24 12.75V23.25C24 23.664 23.664 24 23.25 24H21.75C21.336 24 21 23.664 21 23.25V12.75C21 12.336 21.336 12 21.75 12ZM3.6315 13.2242L9.1635 17.5262C9.558 17.8337 10.044 18.0002 10.5435 18.0002H15.75C13.6785 18.0002 12 16.3217 12 14.2502C12 13.8362 12.336 13.5002 12.75 13.5002H19.5V18.0002V22.5002H9.516C8.682 22.5002 7.872 22.2227 7.2135 21.7097L0.8685 16.7747C-0.112499 16.0127 -0.288 14.5982 0.473999 13.6187C1.236 12.6362 2.6535 12.4607 3.6315 13.2242Z'
              fill='white'
            />
          </svg>
          <span>ЗАКАЗАТЬ</span>
        </div>
        <div ref={favboxref} className={S.richcardFavbox} onClick={favToggle}>
          <svg width='24' height='24' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M8 3.25C7.47889 1.951 6.019 1 4.5 1C2.52211 1 1 2.449 1 4.375C1 7.02175 3.95011 9.0685 8 13C12.0499 9.0685 15 7.02175 15 4.375C15 2.449 13.4779 1 11.5 1C9.97944 1 8.52111 1.951 8 3.25Z'
              fill={favorites.includes(dish.id) ? 'red' : 'white'}
              stroke={favorites.includes(dish.id) ? 'red' : 'white'}
              strokeWidth='2'
            />
          </svg>
        </div>
      </div>
    </DishCard>
  )
})
