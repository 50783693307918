import React, { useEffect, useRef, useState } from 'react'
import S from './RichMenu.module.css'
import { useSelector } from 'react-redux'
import { getCats, getSubcats, getDishes } from '../../state/menu'
import { InView } from 'react-intersection-observer'
import { TweenMax, Power3, Back } from 'gsap/all'
import { Link } from '@reach/router'
import CatTab from '../CatTab'
import Navbar from '../shared/Navbar'
import { RichMenuCard } from './RichMenuCard'
import { SubcatSelector } from './SubcatSelector'
import {useTranslation} from "../../hooks/useTranslation";

export const RichMenu = ({ activeCatId, catCardCoord, ...other }) => {
  const { t } = useTranslation()
  const cats = useSelector(getCats)
  const parentCats = cats.filter(cat => !cat.parentId)
  const dishes = useSelector(getDishes)
  const contentRef = useRef()
  const containerRef = useRef()
  const tabbarRef = useRef()
  const navbarRef = useRef()
  const imgPlaceholderRef = useRef()
  const [catsInView, setCatsInView] = useState([parseInt(activeCatId)])
  const [subcatSelector, setSubcatSelector] = React.useState(false)

  const scrollToCat = id => {
    if (id < 100) window.scrollTo(0, document.getElementById(`cat${id}`).offsetTop - 136)
    else window.scrollTo(0, document.getElementById(`subcat${id / 100}`).offsetTop - 184)
    TweenMax.staggerFrom(
      [...document.querySelectorAll(`.animation-${id}`)].slice(0, 6),
      0.4,
      { y: '+=80', autoAlpha: 0, ease: Back.easeOut.config(1) },
      0.15
    )
  }

  const scrollToSubcat = (id, catId) => {
    window.scrollTo(0, document.getElementById(`subcat${id}`).offsetTop - 184)
    TweenMax.staggerFrom(
      [...document.querySelectorAll(`#subcat${id} .animation-${catId}`)].slice(0, 6),
      0.4,
      { y: '+=80', autoAlpha: 0, ease: Back.easeOut.config(1) },
      0.15
    )
  }

  function updateCatsInView(catId, inView) {
    // add or remove catId from arr
    const arr = inView ? [...catsInView, catId] : catsInView.filter(item => item !== catId)
    setCatsInView(arr.sort())
  }

  useEffect(() => {
    console.log(
      document.getElementsByClassName(S.whiteCardWrapper)[0].offsetTop +
        document.getElementsByClassName(S.whiteCardWrapper)[0].offsetHeight
    )

    const timing = 0.6
    TweenMax.set(contentRef.current, { autoAlpha: 0 })
    TweenMax.to(imgPlaceholderRef.current, timing, { autoAlpha: 0 })
    TweenMax.fromTo(
      containerRef.current,
      timing,
      {
        overflow: 'hidden',
        top: catCardCoord.top,
        left: catCardCoord.left,
        width: catCardCoord.width,
        height: catCardCoord.height,
        ease: Power3.easeOut
      },
      {
        overflow: 'visible',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        onComplete: () => {
          scrollToCat(activeCatId)
        }
      }
    )
    TweenMax.from(tabbarRef.current, timing, {
      width: catCardCoord.width,
      top: catCardCoord.top,
      autoAlpha: 0,
      ease: Power3.easeOut
    })
    TweenMax.to(contentRef.current, timing, {
      autoAlpha: 1,
      ease: Power3.easeOut,
      delay: timing
    })
  }, [])

  return (
    <div ref={containerRef} className='page'>
      <div
        ref={imgPlaceholderRef}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: catCardCoord.bgImg,
          backgroundColor: catCardCoord.bgColor,
          borderRadius: '15px',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      />
      <Navbar ref={navbarRef} id='navbar' icon=''>
        <span>
          <Link
            to='/'
            onClick={e => {
              TweenMax.set(containerRef.current, { overflow: 'hidden' })
              TweenMax.set(tabbarRef.current, { autoAlpha: 0 })
              TweenMax.set(contentRef.current, { autoAlpha: 0 })
              TweenMax.set(navbarRef.current, { autoAlpha: 0 })
              TweenMax.to(containerRef.current, 1, {
                top: catCardCoord.top,
                left: catCardCoord.left,
                width: catCardCoord.width,
                height: catCardCoord.height,
                ease: Power3.easeOut
              })
              TweenMax.to(imgPlaceholderRef.current, 0.3, {
                autoAlpha: 0.3,
                ease: Power3.easeOut
              })
            }}
            onTouchStart={e => TweenMax.to(e.target, 0.15, { scale: 0.8 })}
            onTouchEnd={e => TweenMax.to(e.target, 0.15, { scale: 1 })}
          >
            <img
              src='/img/close-black.svg'
              alt=''
              style={{ width: '44px', padding: '10px', position: 'relative', top: '5px' }}
            />
          </Link>
        </span>

        <span style={{ justifySelf: 'center', maxWidth: '150px', textAlign: 'center' }}>
          Меню
          <div style={{ fontSize: '10px', fontWeight: '500', color: '#979797' }}>Хлебный на Крещатике</div>
        </span>
      </Navbar>

      <div ref={tabbarRef} className={S.whiteCardWrapper}>
        <div className={S.whiteCardBorder}>
          <div className={S.catNav} style={{ width: other.showOrderDrawer ? 'calc( 100% - 32px )' : '100%' }}>
            {cats.map(cat => (
              <CatTab key={`catnav${cat.id}`} scrollToCat={scrollToCat} catsInView={catsInView} cat={cat} />
            ))}
          </div>
        </div>
      </div>
      <div className={`${S.menuContainer} ${S.wrapper} ${other.showOrderDrawer ? S.withorderdrawer : ''}`}>
        <div className={S.content} ref={contentRef}>
          {parentCats.map(parentCat => {
              const subcats = cats.filter(cat => cat.parentId === parentCat.id)
              return (
                  <div key={`cat${parentCat.id}`} className={S.cat}>
                      {<div id={`cat${parentCat.id}`} />}

                      <div
                          as='div'
                          onChange={(inView, _entry) => {
                              // updateCatsInView(parentCat.id, inView)
                          }}
                          className={S.catInView}
                          rootMargin='-136px 0px -65% 0px'
                      >
                          <SubcatSelector
                              catId={parentCat.id}
                              subcats={subcats}
                              scrollToCat={scrollToCat}
                              scrollToSubcat={scrollToSubcat}
                              subcatSelector={subcatSelector}
                              setSubcatSelector={setSubcatSelector}
                          >
                              {t(parentCat.name)}
                          </SubcatSelector>
                      </div>

                      <RichMenuContainer {...{ cat: parentCat, subcats, dishes: dishes.filter(dish => dish.categoryId === parentCat.id), setSubcatSelector }} limitScroll={other.limitScroll} />
                  </div>
              )
          })}
        </div>
      </div>
    </div>
  )
}

const RichMenuContainer = React.memo(({ cat, subcats, dishes, limitScroll, setSubcatSelector }) => {

    const { t } = useTranslation()

    return (
    <>
      {subcats
        .map(subcat => (
          <div key={`subcat${subcat.id}`} id={`subcat${subcat.id}`}>
            <p
              style={{
                margin: 0,
                display: 'inline-block',
                width: '100%',
                height: '40px',
                fontWeight: 'bolder',
                fontSize: '12px',
                lineHeight: '40px',
                textAlign: 'center',
                textTransform: 'uppercase',
                color: '#979797',
                backgroundColor: 'white'
              }}
            >
              {t(subcat.name)}
            </p>
            {dishes
              .filter(dish => subcat.dishIds?.includes(dish.id))
              .map(dish => (
                <RichMenuCard
                  key={`dish${dish.id}`}
                  {...{ dish }}
                  limitScroll={limitScroll}
                  setSubcatSelector={setSubcatSelector}
                />
              ))}
          </div>
        ))}
    </>
  )
})
