import React, { useRef, useLayoutEffect } from 'react'
import s from './Comment.module.css'
import { ReactComponent as CommentIcon } from '../../assets/icons/comment.svg'
import { ReactComponent as CommentOutlineIcon } from '../../assets/icons/comment-outline.svg'
import { ReactComponent as IcoHere } from '../../assets/icons/ico-here.svg'
import { ReactComponent as IcoTakeaway } from '../../assets/icons/ico-takeaway.svg'

export const Comment = props => (props.showComment ? <Content {...props} /> : null)

const Content = ({ setShowComment, comment, setComment, limitScroll }) => {
  // TODO: replace with takeout prop
  const Icon = true ? IcoHere : IcoTakeaway
  // const [textValue, setTextValue] = useState(comment)
  // const placeholder = 'asdfsadsdf\n sdafasdfasf'

  // const applyPlaceholder = () => {
  //   if (textValue != false) {
  //     setTextValue(placeholder)
  //   }
  // }
  const mainRef = useRef()
  useLayoutEffect(() => {
    limitScroll(mainRef.current)
    return () => {
      limitScroll(false)
    }
  }, [])

  useLayoutEffect(() => {
    mainRef.current.focus()
    window.scrollBy(0, -1)
  }, [])

  return (
    <div className={s.wrapper}>
      <nav className={s.navbar}>
        <div className={s.icon} onClick={() => setShowComment(false)}>
          <img src='/img/close-black.svg' alt='' />
        </div>

        <div className={s.right} onClick={() => setShowComment(false)}>
          Сохранить
        </div>
      </nav>

      <div className={s.formWrapper}>
        <div className={s.header}>
          <Icon />
          Комментарий к заказу
        </div>
        <form>
          <textarea
            ref={mainRef}
            className={s.commentTextArea}
            name='textarea'
            autoFocus={true}
            value={comment}
            onChange={e => setComment(e.target.value)}
            placeholder='Оставьте ваш комментарий к заказу'
          />
        </form>
      </div>
    </div>
  )
}

export const CommentBtn = ({ comment, setShowComment, showComment }) => {
  const Icon = comment ? CommentIcon : CommentOutlineIcon
  const fakeRef = useRef()

  useLayoutEffect(() => {
    fakeRef.current.disabled = true
    return () => {
      fakeRef.current.disabled = false
    }
  }, [])

  return (
    <div className={s.btn}>
      <input className={s.fakeinput} onClick={e => setShowComment(true)} ref={fakeRef} />
      <Icon width='16' height='16' />
      <div>{comment ? comment.substring(0, 20) + (comment.length > 20 ? '...' : '') : 'КОММЕНТАРИЙ'}</div>
    </div>
  )
}
