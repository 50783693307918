import React, { useEffect } from 'react'
import { TweenMax, Back } from 'gsap'
import S from './OrderToggler.module.css'
import { ReactComponent as IcoHere } from '../../assets/icons/ico-here.svg'
import { ReactComponent as IcoTakeaway } from '../../assets/icons/ico-takeaway.svg'

const OrderToggler = ({ takeout, setTakeout }) => {
  const switchRef = React.createRef()
  const containerRef = React.createRef()
  // const [firstRun, setNo] = React.useState(true)

  const clickToggle = () => {
    setTakeout(!takeout)
  }

  useEffect(() => {
    // if (!firstRun && switchRef.current) {
    if (switchRef.current) {
      TweenMax.to(switchRef.current, 0.2, {
        left: !takeout ? '49px' : '109px',
        boxShadow: !takeout ? '6px 0px 6px rgba(0, 0, 0, 0.15)' : '-6px 0px 6px rgba(0, 0, 0, 0.15)',
        ease: Back.easeOut.config(1.3)
      })
    }
    // setNo(false)
  }, [takeout])

  return (
    <>
      <div id={S.container} ref={containerRef}>
        <span
          onClick={() => {
            takeout && setTakeout(false)
          }}
          className={!takeout ? S.active : null}
        >
          Здесь
        </span>
        <div id={S.switch} ref={switchRef} onClick={clickToggle}>
          {takeout ? <IcoTakeaway fill='white' /> : <IcoHere fill='white' />}
        </div>
        <div id={S.base} onClick={clickToggle}>
          <IcoHere fill='lightgray' />
          <IcoTakeaway fill='lightgray' />
        </div>
        <span
          onClick={() => {
            !takeout && setTakeout(true)
          }}
          className={takeout ? S.active : null}
        >
          C собой
        </span>
      </div>
    </>
  )
}

export default OrderToggler
