import { SET_VENUE_ID, SET_TABLE_NUM, SET_VENUE_OPEN } from './types'

/* State Shape
{
  venueId: int,
  tableNum: int
}
*/

const initialState = {
  venueId: 1,
  tableNum: 0,
  open: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_VENUE_ID: {
      const { id } = action.payload

      return {
        ...state,
        venueId: id
      }
    }

    case SET_TABLE_NUM: {
      const { num } = action.payload

      return {
        ...state,
        tableNum: num
      }
    }

    case SET_VENUE_OPEN: {
      const { status } = action.payload

      return {
        ...state,
        open: status
      }
    }

    default:
      return state
  }
}
