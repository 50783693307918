import React, { useState, useEffect, useRef } from 'react'
import s from './Checkout.module.css'
import { useSelector } from 'react-redux'
import { getTableNum } from '../../state/location'
import { getDishes } from '../../state/menu'
import { getOrdersNew, getPaymentType } from '../../state/orders'
import { Confirmation } from './Confirmation'
import axios from 'axios'
import { config } from '../../constants'
import Navbar from '../shared/Navbar'
import { money, findId } from '../../utils'
import { isEmpty } from 'ramda'
import cx from 'classnames'

export const Checkout = props => (props.showCheckout ? <Content {...props} /> : null)

const Content = ({ orders, setShowCheckout, comment, setComment, takeout, contentRef, ...rest }) => {
  const tableNum = useSelector(getTableNum)
  const ordersNew = useSelector(getOrdersNew)
  const paymentType = useSelector(getPaymentType)
  const [liqpay, setLiqpay] = useState({})
  const [paymentTakeout, setPaymentTakeout] = useState(null)
  const [paymentTakeoutNum, setpaymentTakeoutNum] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [amountCurrency, setAmountCurrency] = useState(0)
  const load = ordersNew.map(x => ({ dishId: x.dishId, tempId: x.tempId }))

  useEffect(() => {
    const getLiqpayData = async () => {
      try {
        const response = await axios.post(`${config.API_URL}/hlbn/payments`, {
          userId: 1,
          takeout: takeout,
          tableNum: tableNum,
          load: load,
          paymentType: paymentType,
          comment: comment
        })

        setLiqpay(response.data)
        setPaymentTakeout(response.data.takeout)
        setpaymentTakeoutNum(response.data.takeoutNum)
        setDiscount(response.data.discount)
        setAmountCurrency(response.data.amountCurrency)
      } catch (e) {
        console.log(e)
      }
    }
    getLiqpayData()
  }, [])

  // Checkout should be positioned inside <body>
  // hide everything else
  useEffect(() => {
    document.getElementById('root').style.overflow = 'hidden'
    rest.orderDrawer.style.display = 'none'
    rest.limitScroll(false, contentRef.current)
    return () => {
      rest.limitScroll(contentRef.current)
      document.getElementById('root').style.overflow = ''
      rest.orderDrawer.style.display = ''
    }
  }, [])

  return isEmpty(liqpay) ? (
    <div>...loading</div>
  ) : (
    <LiqpayWidget
      {...{
        liqpay,
        orders,
        comment,
        setShowCheckout,
        setComment,
        paymentTakeout,
        paymentTakeoutNum,
        tableNum,
        discount,
        amountCurrency,
        ...rest
      }}
    />
  )
}

const LiqpayWidget = ({
  setShowCheckout,
  orders,
  tableNum,
  liqpay,
  limitScroll,
  comment,
  setComment,
  paymentTakeout,
  paymentTakeoutNum,
  discount,
  amountCurrency
}) => {
  const ordersNew = useSelector(getOrdersNew)
  const wrapperRef = useRef()
  const [displayConfirmation, setDisplayConfirmation] = useState(false)

  useEffect(() => {
    window.LiqPayCheckoutCallback = (function() {
      window.LiqPayCheckout.init({
        data: liqpay.data,
        signature: liqpay.signature,
        embedTo: '#liqpay_checkout',
        language: 'ru',
        mode: 'embed' // embed || popup
      })
        .on('liqpay.callback', function(data) {
          console.log(data.status)
          console.log(data)
          if (data.status === 'success' || data.status === 'wait_accept') {
            setComment('')
            setDisplayConfirmation(true)
          }
        })
        .on('liqpay.ready', function(data) {
          // ready
          document.querySelector('.' + s.loadTitle).style.display = 'none'
        })
        .on('liqpay.close', function(data) {
          // close
        })
    })()
  }, [])

  useEffect(() => {
    ;[...document.getElementById('root').children].map(e => (e.style.display = 'none'))
    document.getElementById('checkoutRef').style.display = ''
    return () => {
      ;[...document.getElementById('root').children].map(e => (e.style.display = ''))
    }
  }, [])

  const ordersUniqueObj = ordersNew.reduce((acc, v) => {
    acc[v.dishId] = (acc[v.dishId] || 0) + 1
    return acc
  }, {})

  const ordersUnique = Object.entries(ordersUniqueObj).map(x => ({ id: parseInt(x[0]), count: x[1] }))

  return displayConfirmation ? (
    <Confirmation {...{ setShowCheckout, paymentTakeout, paymentTakeoutNum, tableNum }} />
  ) : (
    <WidgetView
      {...{
        setShowCheckout,
        orders,
        liqpay,
        limitScroll,
        comment,
        ordersUnique,
        paymentTakeout,
        paymentTakeoutNum,
        tableNum,
        discount,
        amountCurrency,
        wrapperRef
      }}
    />
  )
}

const WidgetView = ({
  setShowCheckout,
  orders,
  liqpay,
  limitScroll,
  comment,
  ordersUnique,
  paymentTakeout,
  tableNum,
  discount,
  amountCurrency,
  wrapperRef
}) => {
  const dishes = useSelector(getDishes)
  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.contentWrapper}>
        <Navbar icon='/img/close-black.svg' onClick={() => setShowCheckout(false)}>
          Оплата ({paymentTakeout ? <span>c собой</span> : <span>столик {tableNum}</span>})
        </Navbar>
        <div className={s.tableWrapper}>
          <table>
            <thead>
              <tr className={s.tableHeader}>
                <th>Товар</th>
                <th className={s.right}>Кол&#8209;во</th>
                <th className={s.right}>Сумма</th>
              </tr>
            </thead>
            <tbody>
              {ordersUnique.map(o => (
                <tr key={o.id}>
                  <td>{findId(o.id, dishes).name}</td>
                  <td className={s.right}>{o.count}</td>
                  <td className={cx(s.right, s.price)}>{money(findId(o.id, dishes).price * o.count)}</td>
                </tr>
              ))}
              {comment ? (
                <tr>
                  <td colSpan='3' style={{ fontStyle: 'italic' }}>
                    Комментарий:
                    <br />
                    {comment}
                  </td>
                </tr>
              ) : null}
              {discount !== '0' ? (
                <tr>
                  <td colSpan='2' style={{ fontStyle: 'italic' }}>
                    Скидка:
                  </td>
                  <td className={cx(s.right, s.price)}>{discount * 100}%</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        <h4 className={s.loadTitle}>
          Определяем способы оплаты
          <br />
          <div className={s.load}>
            <div />
            <div />
            <div />
            <div />
          </div>
        </h4>

        <div className={s.liqpay} id='liqpay_checkout' style={{ position: 'relative' }} />
      </div>
    </div>
  )
}
