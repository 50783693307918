import { TOGGLE_FAVORITE } from './types'

/* State Shape
[
  int
]
*/

const initialState = [1, 2, 3, 4]

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FAVORITE: {
      const { id } = action.payload
      if (state.includes(id)) {
        return state.filter(x => x !== id)
      } else {
        return [...state, id]
      }
    }

    default:
      return state
  }
}
