import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import './DishCard.css'
import { useSelector, useDispatch } from 'react-redux'
import { getCats } from '../state/menu'
import { addNewOrder } from '../state/orders'
import { TweenMax, TimelineMax, Draggable, Back, Ease } from 'gsap/all'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Navbar from './shared/Navbar'
import FavButton from './shared/FavButton'
import CatIcons from './shared/CatIcons'
import Specials from './shared/Specials'
import DishTimer from './shared/DishTimer'
import { findId, money, animateOrder } from '../utils'
import {useTranslation} from "../hooks/useTranslation";

export default function DishCard({ children, dragActive = false, ...other }) {
  const [dishOpen, setDishOpen] = useState(false)
  const smallRef = useRef()

  return (
    <>
      {dishOpen && <Content {...{ smallRef, dishOpen, setDishOpen }} {...other} />}
      <div
        className={`animation-${other.dish ? other.dish.catId : 0}`}
        //className={`animation-${isNaN(other) ? 0 : other.dish.catId}`}
        onClick={e => dragActive || setDishOpen(e.timeStamp)}
        ref={smallRef}
      >
        {children}
      </div>
    </>
  )
}

function Content({ dish = null, smallRef, dishOpen, setDishOpen, orders, limitScroll }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  if (!dish) dish = window.dish
  const cats = useSelector(getCats)
  const wrapperRef = useRef()
  const mainRef = useRef()
  const photoRef = useRef()
  const bgRef = useRef()
  const bgImgRef = useRef()
  const uiRef = useRef()
  const contentRef = useRef()
  const priceRef = useRef()
  const icoRef = useRef()
  const nameCatRef = useRef()
  const nameRef = useRef()
  const btRef = useRef()
  const navRef = useRef()
  const [close, setClose] = useState(false)
  const [preloadComplete, setPreloadComplete] = useState(false)
  const preloadRef = useRef(preloadComplete)
  const stlRef = useRef(new TimelineMax({ paused: true }))
  const tlRef = useRef(new TimelineMax({ onReverseComplete: () => setDishOpen(false), paused: true }))

  useEffect(() => {
    preloadRef.current = preloadComplete
  }, [preloadComplete])

  useLayoutEffect(() => {
    const [wrapper, main, photo, bg, bgimg, ui, content, price, ico, namecat, name, button, nav] = [
      wrapperRef,
      mainRef,
      photoRef,
      bgRef,
      bgImgRef,
      uiRef,
      contentRef,
      priceRef,
      icoRef,
      nameCatRef,
      nameRef,
      btRef,
      navRef
    ].map(e => e.current)
    const small =
      typeof smallRef.current.getElementsByClassName('cat-icon')[0] === 'undefined' ? window.small : smallRef.current
    const refer = small.getBoundingClientRect()
    const smallname = small.getElementsByClassName('cat-icon')[0].parentElement.nextSibling
    const stl = stlRef.current
    const tl = tlRef.current

    // springloaded button animation to hide image preload
    if (!stl.totalDuration()) {
      stl.add(
        TweenMax.fromTo(
          small.firstChild,
          1,
          { transition: 'box-shadow 0s', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' },
          { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 1px 0px' }
        )
      )
      stl.add(TweenMax.to(small.firstChild, 3, { boxShadow: 'rgba(0, 0, 0, 0.5) 0px 3px 5px 1px' }))
    }

    if (!close && !preloadComplete) {
      console.log('Waiting for preload')
      stl.play(0)
      setTimeout(() => {
        if (!preloadRef.current) console.log('Preload failed: forced start')
        wrapper && setPreloadComplete(true)
      }, 1500)
    }
    // end of springloaded button animation
    if (!tl.totalDuration()) {
      // init timeline only once

      // Drag down to close
      const d = Draggable.create(ui, {
        type: 'y',
        // trigger: main,
        zIndexBoost: false,
        // bounds: { minY: ui.getBoundingClientRect().y - ui.getBoundingClientRect().height, maxY: 300 },
        dragClickables: true,
        allowContextMenu: true,
        dragResistance: 0.4,
        edgeResistance: 0.999,
        throwProps: true,
        onDragStart: () => {
          const min = window.innerHeight - window.innerWidth / 2 - ui.getBoundingClientRect().height
          d[0].applyBounds({
            minY: min < 0 ? min : 0,
            maxY: window.innerHeight * 0.75
          })
        },
        onDrag: () => {
          if (ui._gsTransform.y > window.innerWidth / 5) TweenMax.set(ui, { y: window.innerWidth / 5 })
          if (d[0].endY > 0) {
            TweenMax.set(wrapper, {
              transform: `scale(${1 - d[0].endY / window.innerHeight})`
            })
            // animate navbar
            TweenMax.set(nav, {
              y: 0 - (1 - wrapper._gsTransform.scaleY) * 500
            })
            // animate button
            TweenMax.set(button, {
              y: 0 + (1 - wrapper._gsTransform.scaleY) * 500 * 2.5
            })
          }
        },
        onThrowUpdate: () => {
          if (ui._gsTransform.y > 0) TweenMax.to(ui, 0.3, { y: 0 })
        },
        onDragEnd: () => {
          if (wrapper._gsTransform.scaleY < 0.88) {
            tl.clear()
            tl.add(
              TweenMax.from(wrapper, 1, {
                transform: 'scale(0.1)',
                left: refer.x - window.innerWidth / 2 + refer.x / 2,
                top: refer.y - window.innerHeight / 2 + refer.height / 2,
                ease: Ease.EaseIn
              })
            )
            tl.duration(0.35)
            setClose(true)
          } else {
            TweenMax.to(wrapper, 0.3, { transform: 'scale(1)' })
            if (ui._gsTransform.y > 0) TweenMax.to(ui, 0.3, { y: 0 })
            TweenMax.to(nav, 0.3, { y: 0 })
            TweenMax.to(button, 0.3, { y: 0 })
          }
        }
      })
      // end of draggable

      // animate box
      tl.add(
        TweenMax.fromTo(
          wrapper,
          1.1,
          {
            y: refer.y,
            height: refer.height,
            ease: Back.easeOut.config(2)
          },
          { y: 0, height: '100%' }
        )
      )
      tl.add(TweenMax.fromTo(wrapper, 0.01, { visibility: 'hidden' }, { visibility: 'visible' }), 0)
      TweenMax.set(button, { y: 80 })

      // animate content card
      tl.add(
        TweenMax.fromTo(
          ui,
          1,
          {
            x: smallname.getBoundingClientRect().x - ui.getBoundingClientRect().x - 40,
            y: smallname.getBoundingClientRect().y - ui.getBoundingClientRect().y - 16
          },
          { x: 0, y: 0 }
        ),
        0
      )

      //animate category name
      tl.add(TweenMax.fromTo(namecat, 0.5, { autoAlpha: 0 }, { autoAlpha: 1 }))
      // animate button
      tl.add(TweenMax.fromTo(button, 0.5, { y: 140 }, { y: 0 }), '-=.5')
      // animate navbar
      tl.add(TweenMax.from(nav, 0.5, { y: -60 }), '-=.5')

      // other
      tl.add(
        TweenMax.fromTo(
          ui,
          1.5,
          {
            y: '-40',
            ease: Back.easeOut.config(1)
          },
          { y: 0 }
        ),
        '-=1'
      )
      tl.add(
        TweenMax.from([photo, bg], 1.5, {
          y: '-=20px',

          ease: Back.easeOut.config(1)
        }),
        '-=1'
      )
      tl.add(
        TweenMax.from(content, 0.4, {
          y: '-=50',
          autoAlpha: 0,
          ease: Back.easeOut.config(1)
        }),
        '-=0.4'
      )
      tl.add(
        TweenMax.from(price, 0.4, {
          y: '-=10',
          autoAlpha: 0,
          ease: Back.easeOut.config(1)
        }),
        '-=0.4'
      )
    }

    if (close) {
      // tl.duration(0.7)
      tl.reverse(0)
      stl.seek(0)
    } else if (preloadComplete) {
      stl.duration(0.7)
      tl.duration(1)
      window.tl = tl
      window.stl = stl
      tl.seek(0)
      tl.play()
      stl.pause()
    }
  }, [close, smallRef, setDishOpen, preloadComplete])

  useEffect(() => {
    limitScroll(mainRef.current)
    return () => {
      limitScroll(false)
    }
  }, [limitScroll])
  const [darkTheme, setDarkTheme] = useState(false)

  function applyColor(ev) {
    var img = bgImgRef.current
    var canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height)
    var p = canvas.getContext('2d').getImageData(10, img.height - 10, 1, 1).data
    setDarkTheme(p[0] + p[1] + p[2] < 127 * 3)
    photoRef.current.style.backgroundImage = `url(${bgImgRef.current.src})`
    var duration = ev.timeStamp - dishOpen
    if (duration < 0) duration = 0
    setTimeout(() => {
      setPreloadComplete(true)
      try {
        let a = ''
        ;[
          ...document.getElementsByClassName('dish-card-full')[0].getElementsByClassName('react-tabs__tab-panel')
        ].reverse()[0].innerText = a =
          'Preload complete in ' + Math.round(duration) + 'ms, ' + Math.round(300 - duration) + 'ms left'
        console.log(a)
      } catch (e) {
        console.log(e)
      }
    }, 300 - duration)
  }

  const img = dish.img ? `/${dish.img}` : '/assets/imgs/toast1.jpg'

  // check browser support backdrop-filter

  const contentBgc = { backgroundColor: darkTheme ? 'rgba(0, 0, 0, 0.25)' : 'rgba(255, 255, 255, 0.25)' }
  const bgStyle = {}
  if (!(CSS.supports('-webkit-backdrop-filter', 'blur(5px)') || CSS.supports('backdrop-filter', 'blur(5px)'))) {
    contentBgc.backgroundColor = darkTheme ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255, 0.75)'
    bgStyle.filter = 'blur(10px)'
    bgStyle.transform = 'scale(1.1)'
  }
  return (
    <>
      <Navbar
        style={{ zIndex: 90, background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))', left: 0 }}
        ref={navRef}
        icon=''
      >
        <div
          onTouchStart={e => TweenMax.to(e.target, 0.15, { scale: 0.8 })}
          onTouchEnd={e => TweenMax.to(e.target, 0.15, { scale: 1 })}
          onClick={e => {
            setClose(true)
          }}
          style={{ padding: '30px', top: 0, left: '-30px', position: 'relative' }}
        >
          <img src='/img/close.svg' alt='' />
        </div>
      </Navbar>
      <div className='dish-card-full-wrapper' ref={wrapperRef}>
        <div className='dish-card-full-photo' ref={photoRef} />
        <div className={'dish-card-background-photo-wrapper'} ref={bgRef} style={bgStyle}>
          <div className={'dish-card-background-photo-cover'} />
          <img className='dish-card-background-photo' alt='' src={img} onLoad={applyColor} ref={bgImgRef} />
        </div>
        <div className='dish-card-full' ref={mainRef}>
          <div className={`dish-card-full__content ${darkTheme ? 'dark' : 'light'}`} ref={uiRef} style={contentBgc}>
            <div className='dish-card-dragshape' />
            <div className='dish-card-full__top-line'>
              <div style={{ marginRight: '8px' }} ref={icoRef}>
                <CatIcons color={darkTheme ? '#fff' : '#000'} icon={findId(dish.catId, cats)?.icon} size={24} />
              </div>
              <div className='cat-name' ref={nameCatRef}>
                {t(findId(dish.catId, cats)?.name || '')}
              </div>
              <div className='right'>
                <FavButton dishId={dish.id} style={{ top: '16px', right: '16px' }} />
              </div>
            </div>
            <h1 style={{ fontSize: '24px', position: 'relative' }} ref={nameRef}>
              {t(dish.name)}
            </h1>
            <div ref={priceRef} className='priceline'>
              <DishTimer {...{ darkTheme }} />
              <div className='right' style={{ color: '#00d422' }}>
                {money(dish.price)}.
              </div>
            </div>
            <div>
              <Specials specials={null} />
            </div>
            <Tabs ref={contentRef}>
              <TabList>
                <Tab>
                  <div className='tab-title'>О блюде</div>
                </Tab>
                <Tab>
                  <div className='tab-title'>Калории</div>
                </Tab>
                {/* <Tab>
                  <div className='tab-title'>Повар</div>
                </Tab> */}
              </TabList>

              <TabPanel>
                <div className='tab-content'>
                  <div>{t(dish.desc)}</div>
                  Здесь будет подробное описание блюда. Пока его нет.
                  <br />
                </div>
              </TabPanel>
              <TabPanel>
                <h2>Any content 1</h2>
                <h2>Any content 2</h2>
                <h2>Any content 3</h2>
                <h2>Any content 4</h2>
                <h2>Any content 5</h2>
                <h2>Any content 6</h2>
                <h2>Any content 7</h2>
                <h2>Any content 1</h2>
                <h2>Any content 2</h2>
                <h2>Any content 3</h2>
                <h2>Any content 4</h2>
                <h2>Any content 5</h2>
                <h2>Any content 6</h2>
                <h2>Any content 7</h2>
              </TabPanel>
              {/* <TabPanel>
                <h2>Вы можете изменить заказ под свой вкус</h2>
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
      </div>
      {dish.stoplist ? (
        <button
          className='dish-card-full-order-button stoplist'
          onTouchStart={() => TweenMax.to(btRef.current, 0.15, { scale: 0.95 })}
          onTouchEnd={() => TweenMax.to(btRef.current, 0.15, { scale: 1 })}
          ref={btRef}
        >
          <CatIcons color='#fff' icon={findId(dish.catId, cats)?.icon} size={24} />
          <span>ЗАКОНЧИЛОСЬ :(</span>
        </button>
      ) : (
        <button
          className='dish-card-full-order-button'
          onTouchStart={() => TweenMax.to(btRef.current, 0.15, { scale: 0.95 })}
          onTouchEnd={() => TweenMax.to(btRef.current, 0.15, { scale: 1 })}
          onClick={() => {
            dispatch(addNewOrder(dish))
            setTimeout(() => {
              animateOrder(btRef.current)
            }, 100)
          }}
          ref={btRef}
        >
          <CatIcons color='#fff' icon={findId(dish.catId, cats)?.icon} size={24} />
          <span>В ЗАКАЗ</span>
        </button>
      )}
    </>
  )
}
