import React from 'react'
import s from './SidePanel.module.css'
import { useDispatch } from 'react-redux'
import { load, unload } from '../state/loader'
import { Loader } from './Loader'

import UserProfile from './UserProfile'
import Loyalty from './Loyalty'
import Preferences from './Preferences'
import OrderHistory from './OrderHistory'
import Feedback from './Feedback'

export const SidePanel = () => {
  const dispatch = useDispatch()

  return (
    <>
      <Loader>
        <UserProfile name='UserProfile' />
        <Loyalty name='Loyalty' />
        <Preferences name='Preferences' />
        <OrderHistory name='OrderHistory' />
        <Feedback name='Feedback' />
      </Loader>

      <div className={s.wrapper}>
        <h1>Side Panel</h1>
        <div onClick={() => dispatch(unload('SidePanel'))}>[x] Close</div>
        <div onClick={() => dispatch(load('UserProfile'))}>User Profile</div>
        <div onClick={() => dispatch(load('VenueSelect'))}>Venue Select</div>
        <div onClick={() => dispatch(load('Loyalty'))}>Loyalty</div>
        <div onClick={() => dispatch(load('Preferences'))}>Preferences</div>
        <div onClick={() => dispatch(load('OrderHistory'))}>Order History</div>
        <div onClick={() => dispatch(load('QRScan'))}>QR Scan</div>
        <div onClick={() => dispatch(load('Feedback'))}>Feedback</div>
        <div onClick={() => dispatch(load('GetContactInfo'))}>GetContactInfo</div>
      </div>
    </>
  )
}
