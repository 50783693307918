import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import './App.css'
import { Loader } from './components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { load } from './state/loader'
import { fetchVenues, getVenueById } from './state/venues'
import { getFirstTime, getUserId } from './state/user'
import { addCats, addSubcats, addDishes } from './state/menu'
import { fetchPaidOrders, getOrdersNew, getOrdersPaid } from './state/orders'
import { getVenueId, getVenueOpen, setVenueOpen } from './state/location'
import { Router, LocationProvider, createMemorySource, createHistory, Location } from '@reach/router'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import axios from 'axios'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { config } from './constants'
import { Home } from './components/Home'
import { RichMenu } from './components/RichMenu'
import OrderDrawer from './components/OrderDrawer'

import DishCard from './components/DishCard'
import OrderType from './components/OrderType'
import Onboarding from './components/Onboarding'

import { SidePanel } from './components/SidePanel'
import { GetContactInfo } from './components/GetContactInfo'
import { QRUrl, QRScan } from './components/QRcode'
import { VenueSelect } from './components/VenueSelect'
import { isVenueOpen } from './utils'

let source = createMemorySource('/')
let history = createHistory(source)

export default function App() {
  const dispatch = useDispatch()

  const firstTime = useSelector(getFirstTime)
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)
  const userId = useSelector(getUserId)
  const ordersNew = useSelector(getOrdersNew)
  const ordersPaid = useSelector(getOrdersPaid)

  const [showOrderDrawer, setShowOrderDrawer] = useState(false)
  // const [takeout, setTakeout] = useState(undefined)
  const [takeout, setTakeout] = useState(false) // for testing state

  const [catCardCoord, setCatCardCoord] = useState(null)

  const venueOpen = useSelector(getVenueOpen)

  // console.log(config)

  useEffect(() => {
    if (venue) {
      dispatch(setVenueOpen(isVenueOpen(venue)))
    }
  }, [venue])

  useEffect(() => {
    if (venue) {
      const isVenueOpenInterval = setInterval(() => {
        const newVenueOpen = isVenueOpen(venue)
        // console.log('venueId venueOpen : newVenueOpen = ', venue.id, venueOpen, ' : ', newVenueOpen)
        if (venueOpen !== newVenueOpen) {
          dispatch(setVenueOpen(newVenueOpen))
          clearInterval(isVenueOpenInterval)
        }
      }, 3000)
    }
  }, [venue, venueOpen])

  // при отсутствии venueId закрузить компонент VenueSelect
  useLayoutEffect(() => {
    if (!venueId) {
      dispatch(load('VenueSelect'))
    }
  }, [venueId])

  // внести рабочие часы в location после выбора заведения и получения рабочих часов данного заведения
  useEffect(() => {
    if (venueId && venue) {
      // dispatch(setWorkingHours(venue.workingHours))
    }
  }, [venueId, venue])

  // fetch меню при изменении venueId
  // для скачивания меню нужно ждать выбор заведения. Обдумать необходимость наличия заведения по умолчанию
  useEffect(() => {
    if (venueId) {
      const fetchCats = async () => {
        const resultCats = await axios(`${config.API_URL}/hlbn/categories`)
        dispatch(addCats(resultCats.data))
      }
      fetchCats()

      /*const fetchSubcats = async () => {
        const resultSubcats = await axios(`${config.API_URL}/hlbn/subcategories`)
        dispatch(addSubcats(resultSubcats.data))
      }
      fetchSubcats()*/

      const fetchDishes = async () => {
        const resultDishes = await axios(`${config.API_URL}/hlbn/dishes`)
        dispatch(addDishes(resultDishes.data))
      }
      fetchDishes()
    }
  }, [dispatch, venueId])

  let scrollstack = useRef(['scroll stack clear'])

  // limitScroll(element, [children]) - disable scroll for anything but 'element' and its children (optional)
  // limitScroll(false) - remove last scroll lock
  // limitScroll(false, element) - remove specific scroll lock
  const limitScroll = (el, opt = false) => {
    var s = scrollstack.current
    if (el) {
      if (!(el instanceof Element)) throw new Error('limitScroll(el, opt): el should be a valid DOM element')
      console.log(
        'setting scroll lock for: ',
        el,
        s.push(el),
        disableBodyScroll(el, { allowTouchMove: opt ? el => el.className.includes(opt) : false })
      )
    } else if (opt instanceof Element) {
      s.splice(s.indexOf(opt), 1)
      enableBodyScroll(opt)
      console.log('limitScroll: removing lock for: ', opt, 's:', s)
    } else console.log('clearing scroll lock for: ' + s[s.length - 1], enableBodyScroll(s.pop()), 's:', s)
    if (s.length === 0 || s.length > 4) {
      console.error('Scroll stack out of bounds')
      clearAllBodyScrollLocks()
    }
    return true
  }

  const coordProvider = obj => {
    setCatCardCoord(obj)
  }

  // fetch Orders paid from API
  /*useEffect(() => {
    const fetchPaidOrdersAsync = async () => {
      const result = await axios(`${config.API_URL}/hlbn/orders`)
      dispatch(fetchPaidOrders(result.data))
    }
    fetchPaidOrdersAsync()
  }, [])*/

  // fetch Venues from API
  useEffect(() => {
    const fetchVenuesAsync = async () => {
      const result = await axios(`${config.API_URL}/hlbn/venues`)
      dispatch(fetchVenues(result.data?.venues || []))
    }
    fetchVenuesAsync()
  }, [])

  // Subscribe to orders websocket
  // useEffect(() => {
  //   const cable = ActionCable.createConsumer(config.WS_URL)
  //   cable.subscriptions.create('OrdersChannel', {
  //     received: data => {
  //       dispatch(processWebsocketOrder(data))
  //       dispatch(animateOrders(data))
  //     },
  //     speak: data => false
  //   })
  // }, [])

  // Subscribe to stoplist websocket
  // useEffect(() => {
  //   const cable = ActionCable.createConsumer(config.WS_URL)
  //   cable.subscriptions.create('StoplistChannel', {
  //     received: data => {
  //       dispatch(toggleStoplist(data))
  //     },
  //     speak: data => false
  //   })
  // }, [])

  useEffect(() => {
    setShowOrderDrawer(ordersNew.length > 0 || ordersPaid.length > 0)
  }, [ordersNew, ordersPaid])

  return (
    <>
      <QRUrl default {...{ setTakeout }} />

      {firstTime && <Onboarding {...{ limitScroll, takeout, setTakeout }} />}

      {!firstTime && takeout === undefined && <OrderType {...{ limitScroll, takeout, setTakeout }} />}

      <Loader>
        <VenueSelect name='VenueSelect' />
        <SidePanel name='SidePanel' />
        <GetContactInfo name='GetContactInfo' />
        <QRScan name='QRScan' />
      </Loader>

      <LocationProvider history={history}>
        <OrderDrawer
          {...{
            showOrderDrawer,
            limitScroll,
            takeout,
            setTakeout
          }}
        />
        <DishCard {...{ dragActive: false, limitScroll }}>
          <div id='fakeID' />
        </DishCard>
        <FadeTransitionRouter>
          <Home
            path='/'
            {...{
              coordProvider,
              catCardCoord,
              showOrderDrawer,
              limitScroll
            }}
          />
          <RichMenu path='/richmenu/:activeCatId' {...{ catCardCoord, showOrderDrawer, limitScroll }} />
        </FadeTransitionRouter>
      </LocationProvider>
    </>
  )
}

const FadeTransitionRouter = props => (
  <Location>
    {({ location }) => (
      <TransitionGroup className='transition-group'>
        <CSSTransition key={location.pathname} classNames='fade' timeout={500}>
          <Router location={location} className='router'>
            {props.children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
)
