import React from 'react'
import S from './OrderHandle.module.css'
import { ReactComponent as IcoHere } from '../../assets/icons/ico-here.svg'
import { ReactComponent as IcoTakeAway } from '../../assets/icons/ico-takeaway.svg'

export default function OrderHandle({ state, manualDrag, takeout }) {
  const icon = takeout ? (
    <IcoTakeAway fill={state ? 'lightgray' : 'white'} className={S.handleIcon} />
  ) : (
    <IcoHere fill={state ? 'lightgray' : 'white'} className={S.handleIcon} />
  )
  return (
    <div className='orderDraggable'>
      {icon}
      <svg
        width='48'
        height='138'
        viewBox='0 0 48 138'
        className={state ? `${S.handleShape} ${S.opn}` : `${S.handleShape} ${S.cls}`}
      >
        <defs>
          <linearGradient
            id='paint0_linear'
            x1='76'
            y1='85'
            x2='28'
            y2='85'
            gradientUnits='userSpaceOnUse'
            gradientTransform='matrix(1, 0, 0, 1, -32, 0)'
          >
            <stop stopOpacity='0.25' offset='0' />
            <stop offset='0.5' stopColor='white' stopOpacity='0' />
          </linearGradient>
        </defs>

        <path
          d='M48 0v138c0-12.5-8.5-19.6-18.1-24.5a48 48 0 0 1 0-89C39.5 19.5 48 12.5 48 0z'
          fill={state ? 'white' : '#ff005c'}
        />
        <path
          d='M48 0v138c0-12.5-8.5-19.6-18.1-24.5a48 48 0 0 1 0-89C39.5 19.5 48 12.5 48 0z'
          fill={state ? 'white' : 'url(#paint0_linear)'}
        />
      </svg>
    </div>
  )
}
