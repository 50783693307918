import React from 'react'
import { useDispatch } from 'react-redux'
import { unload } from '../state/loader'
import styled from 'styled-components'

export default function Loyalty({ setComp }) {
  const dispatch = useDispatch()
  return (
    <Wrapper>
      <h1>Loyalty</h1>
      <div>
        <div onClick={() => dispatch(unload('Loyalty'))}>[x] Close</div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #999;
  z-index: 2000;
`
