import React from 'react'
import s from './PaymentBtn.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getTableNum } from '../../state/location'
import { getOrdersNew } from '../../state/orders'
import { load } from '../../state/loader'
import cx from 'classnames'
import { ReactComponent as TableIcon } from '../../assets/icons/orderTypeTableWhite.svg'

export const PaymentBtn = ({ actions, takeout }) => {
  const tableNum = useSelector(getTableNum)
  const ordersNew = useSelector(getOrdersNew)

  const [setShowCheckout, setQrShow] = actions
  const disabled = ordersNew.length > 0 ? false : true
  let text = 'К оплате'
  // let action = setShowCheckout
  let action = 'showCheckout'

  const showTable = !takeout && tableNum ? true : null

  if (!disabled && !takeout && !tableNum) {
    text = 'Выбрать столик'
    // action = setQrShow
    action = 'showQRScan'
  }

  return disabled ? (
    <ButtonDisabled {...{ tableNum, showTable }} />
  ) : (
    <Button {...{ text, action, tableNum, showTable, setShowCheckout }} />
  )
}

const Button = ({ text, action, tableNum, showTable, setShowCheckout }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    if (action === 'showCheckout') {
      setShowCheckout(true)
    } else {
      dispatch(load('QRScan'))
    }
  }

  return (
    <div className={s.button} onClick={() => handleClick()}>
      <div>{text}</div>
      {showTable && <TableView {...{ tableNum }} />}
    </div>
  )
}

const ButtonDisabled = ({ tableNum, showTable }) => (
  <div className={cx(s.button, s.disabled)}>
    <div>Приятного аппетита</div>
    {showTable && <TableView {...{ tableNum }} />}
  </div>
)

const TableView = ({ tableNum }) => (
  <>
    <TableIcon fill='#ffffff' width='28' height='16' />
    <div>#{tableNum}</div>
  </>
)
