import React from 'react'
import s from './FavButton.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { toggleFavorite } from '../../state/favorites'

export default function FavButton({ dishId, style }) {
  const dispatch = useDispatch()
  const favorites = useSelector(state => state.favorites)

  function toggleButton(e, dishId) {
    e.stopPropagation()
    dispatch(toggleFavorite(dishId))
  }

  const likedIcon = favorites.includes(dishId) ? '/img/heart.svg' : '/img/heart-outline.svg'

  return (
    <div className={s.button} {...{ style }}>
      <img src={likedIcon} className={s.image} onClick={e => toggleButton(e, dishId)} alt='' />
    </div>
  )
}
