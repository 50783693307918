import React, { useEffect, useState } from 'react'
import s from './Home.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { load } from '../state/loader'
import { getVenueOpen } from '../state/location'
import { Link } from '@reach/router'
import Navbar from './shared/Navbar'
import FlatMenu from './FlatMenu'
import SubcatPanel from './Home/SubcatPanel'
import classnames from 'classnames'

import packageJson from '../../package.json'

export const Home = props => {
  let loadContent = true
  // const workingHours = useSelector(getWorkingHours)

  // if (!isEmpty(workingHours)) {
  //   loadContent = true
  // }

  return loadContent ? <Content {...props} /> : <div>LOADING</div>
}

const Content = ({ setShowSidePanel, showOrderDrawer, catCardCoord, ...other }) => {
  const [showSubcatPanel, setShowSubcatPanel] = useState(false)
  const dispatch = useDispatch()
  const venueOpen = useSelector(getVenueOpen)
  const menuCatCardRefs = []
  for (let i = 0; i < 7; i++) {
    menuCatCardRefs.push(React.createRef())
  }
  const clickHandler = (id, e) => {
    const param = {
      bgColor: menuCatCardRefs[id].current.style.backgroundColor,
      bgImg: menuCatCardRefs[id].current.style.backgroundImage,
      top: menuCatCardRefs[id].current.getBoundingClientRect().y,
      left: menuCatCardRefs[id].current.getBoundingClientRect().x,
      width: menuCatCardRefs[id].current.getBoundingClientRect().width,
      height: menuCatCardRefs[id].current.getBoundingClientRect().height
    }
    other.coordProvider(param)
    if (id === 1) {
      e.preventDefault()
      setShowSubcatPanel(true)
    }
  }

  return (
    <ScrollToTop>
      <Navbar onClick={() => dispatch(load('SidePanel'))}>
        Хлебный
        <span
          style={{ position: 'fixed', fontWeight: '300', color: '#999', right: '5px', top: '5px', fontSize: '11px' }}
        >
          v{packageJson.version}.{window.buildNum}
          <br />
          {window.branch}
        </span>
      </Navbar>
      <div className={classnames(s.homeWrapper, showOrderDrawer ? s.drawerOpen : s.drawerClosed)}>
        {venueOpen ? null : <div className={s.closedNotification}>Заведение сейчас закрыто</div>}
        {showSubcatPanel && <SubcatPanel {...{ setShowSubcatPanel, showOrderDrawer, catCardCoord }} />}
        <div className={s.homeContainer}>
          <h1>Меню</h1>

          <div className={s.row}>
            <Link
              ref={menuCatCardRefs[0]}
              onClick={e => {
                clickHandler(0, e)
              }}
              className={classnames(s.squareCard, s.double)}
              to='richmenu/2'
              style={{ backgroundImage: 'url(img/img-breakfast-2x.jpg)' }}
            >
              <div style={{ alignItems: 'flex-end' }}>
                <img className={s.icon} src='img/ico-breakfast.svg' alt='' />
                <div>Завтраки</div>
              </div>
            </Link>
          </div>

          <div className={s.row}>
            <Link
              ref={menuCatCardRefs[1]}
              onClick={e => {
                clickHandler(1, e)
              }}
              className={s.squareCard}
              to='richmenu/1'
              style={{ backgroundImage: 'url(img/img-beverages@2x.png)' }}
            >
              <div style={{ alignItems: 'flex-end' }}>
                <img className='icon' src='img/ico-beverages.svg' alt='' />
                <div>Напитки</div>
              </div>
            </Link>
            <Link
              ref={menuCatCardRefs[2]}
              onClick={e => {
                clickHandler(2, e)
              }}
              className={s.squareCard}
              to='richmenu/5'
              style={{ backgroundImage: 'url(img/img-bakery@2x.png)' }}
            >
              <div>
                <img className='icon' src='img/ico-bakery.svg' alt='' />
                <div>Выпечка</div>
              </div>
            </Link>
          </div>

          <div className={s.row}>
            <Link
              ref={menuCatCardRefs[4]}
              onClick={e => {
                clickHandler(4, e)
              }}
              className={s.squareCard}
              to='richmenu/3'
              style={{ backgroundImage: 'url(img/img-hotsandwich@2x.png)' }}
            >
              <div>
                <img className={s.icon} src='img/ico-hotsandwich.svg' alt='' />
                <div>Горячие сэндвичи</div>
              </div>
            </Link>
            <Link
              ref={menuCatCardRefs[3]}
              onClick={e => {
                clickHandler(3, e)
              }}
              className={s.squareCard}
              to='richmenu/3'
              style={{
                backgroundImage: 'url(img/img-sandwich@2x.png)'
                // backgroundColor: '#000'
              }}
            >
              <div style={{ alignItems: 'flex-end' }}>
                <img className={s.icon} src='img/ico-sandwich.svg' alt='' />
                <div style={{ textAlign: 'right' }}>Холодные сэндвичи</div>
              </div>
            </Link>
          </div>

          <div className={s.row}>
            <Link
              ref={menuCatCardRefs[5]}
              onClick={e => {
                clickHandler(5, e)
              }}
              className={s.squareCard}
              to='richmenu/6'
              style={{
                backgroundImage: 'url(img/img-bread@2x.png)'
              }}
            >
              <div style={{ alignItems: 'flex-end' }}>
                <img className={s.icon} src='img/ico-bread.svg' alt='' />
                <div style={{ color: 'white' }}>Хлеб</div>
              </div>
            </Link>
            <Link
              ref={menuCatCardRefs[6]}
              onClick={e => {
                clickHandler(6, e)
              }}
              className={s.squareCard}
              to='richmenu/4'
              style={{
                backgroundImage: 'url(img/img-salad@2x.png)',
                backgroundColor: '#DBFF00'
              }}
            >
              <div>
                <img className={s.icon} src='img/ico-salad.svg' alt='' />
                <div>Салаты</div>
              </div>
            </Link>
          </div>

          {/* <div className={s.catInactive}>
            <img className={s.icon} src='img/lunch.svg' alt='' />
            <strong>Бизнес Ланчи</strong> [12:00 - 16:00]
          </div> */}
        </div>

        <FlatMenu {...other} />
      </div>
    </ScrollToTop>
  )
}

function ScrollToTop({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return children
}
