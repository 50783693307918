import React, { useEffect } from 'react'
import S from './Navbar.module.css'

// set icon = '' if you want to pass icons as children
const Navbar = React.forwardRef(({ icon = '/img/tune.svg', children, onClick = null, style }, ref) => {
  // Navbar auto-shadow for Home.js
  useEffect(() => {
    if (icon !== '/img/tune.svg' && icon !== ' ') return
    function navShadow() {
      let n = document.querySelector('.' + S.navbar)
      if (window.scrollY > 3) {
        if (document.querySelectorAll('.' + S.shadow).length === 0) {
          n.classList.add(S.shadow)
        }
      } else {
        n.classList.remove(S.shadow)
      }
    }

    window.addEventListener('scroll', navShadow)
    return () => {
      window.removeEventListener('scroll', navShadow)
    }
  }, [])

  return (
    <nav className={S.navbar} style={style} ref={ref}>
      {icon === '' ? null : (
        <div className={S.icon} onClick={onClick}>
          <img src={icon} alt='' />
        </div>
      )}
      {children}
      <div className={S.icon}>&nbsp;</div>
    </nav>
  )
})
export default Navbar
