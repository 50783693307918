import { ADD_CATS, ADD_SUBCATS, ADD_DISHES, TOGGLE_STOPLIST } from './types'

export const addCats = cats => ({
  type: ADD_CATS,
  payload: { cats }
})

export const addSubcats = subcats => ({
  type: ADD_SUBCATS,
  payload: { subcats }
})

export const addDishes = dishes => ({
  type: ADD_DISHES,
  payload: { dishes }
})

export const toggleStoplist = ({ id, stoplist }) => ({
  type: TOGGLE_STOPLIST,
  payload: { id: id, stoplist: stoplist }
})
