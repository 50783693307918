import React from 'react'
import s from './Specials.module.css'

const Specials = (specials = []) => {
  return null

  // specials = [{ name: 'Третье бесплатно', background: '#DEAD00' }, { name: 'Бонус+', background: '#00BCD4' }]

  // return (
  //   <div className={s.wrap}>
  //     {specials.map(({ name, background }, i) => (
  //       <div className={s.item} style={{ background: background }} key={i}>
  //         {name}
  //       </div>
  //     ))}
  //   </div>
  // )
}

export default Specials
