import React from 'react'
import { useDispatch } from 'react-redux'
import { unload } from '../state/loader'

export default function UserProfile() {
  const dispatch = useDispatch()

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        background: '#666',
        zIndex: '2000'
      }}
    >
      <h1>UserProfile</h1>
      <div>
        <div onClick={() => dispatch(unload('UserProfile'))}>[x] Close</div>
      </div>
    </div>
  )
}
