import React from 'react'
import s from './VenueSelect.module.css'
import cx from 'classnames'
import {useTranslation} from "../../hooks/useTranslation";

export const VenueView = ({ venue, handleSetVenue, activeVenue }) => {
  const selected = {
    [s.selected]: activeVenue === venue.id
  }

  const { t } = useTranslation()

  return (
    <div className={cx(s.venueCard, selected)} onClick={handleSetVenue(venue)}>
      <div className={s.info}>
        <div className={s.name}>{t(venue.name)}</div>
        <div className={s.address}>{t(venue.address)}</div>
      </div>
    </div>
  )
}
