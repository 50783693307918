import orders from './reducers'

export {
  fetchPaidOrders,
  addNewOrder,
  removeNewOrder,
  processWebsocketOrder,
  setPaymentType,
  setTakeout
} from './actions'

export { getOrdersCooking, getOrdersReady, getOrdersNew, getOrdersPaid, getOrders, getPaymentType } from './selectors'

export default orders
