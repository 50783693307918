import React, { useState, useRef, useEffect } from 'react'
import s from './CookTimer.module.css'

const CookTimer = ({ start, time }) => {
  // dish.time, in seconds
  if (isNaN(start) || isNaN(time)) throw new Error('Invalid data:', start, time)
  // console.log(`start=${start} time=${time}`)
  let bar = useRef()
  let [seconds, setSeconds] = useState(1)
  let [minutes, setMinutes] = useState(0)
  const minRef = useRef(minutes)
  const secRef = useRef(seconds)
  const timeoutRef = useRef()

  const changePercent = function() {
    const now = Math.round(new Date().getTime() / 1000)
    setSeconds((start + time - now) % 60)
    setMinutes(Math.floor((start + time - now) / 60))
    let val = time > 0 ? ((now - start) / time) * 100 : 100
    // console.log('time =', time, 'm =', Math.floor((start + time - now) / 60), 's =', secRef.current, '% =', val)

    const circle = bar.current
    if (!circle) return

    if (isNaN(val)) {
      throw new Error('Sum Ting Wong')
    } else {
      let r = circle.getAttribute('r')
      let c = Math.PI * (r * 2)
      if (val < 0) {
        val = 0
      }
      if (val > 100) {
        val = 100
      }
      let pct = Math.round((val / 100) * c * 10) / 10
      circle.style.strokeDashoffset = pct
    }
    if (minRef.current + secRef.current > 0) timeoutRef.current = setTimeout(changePercent, 1000)
  }

  useEffect(() => {
    minRef.current = minutes
    secRef.current = seconds
  }, [minutes, seconds])

  useEffect(() => {
    changePercent()
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <div className={s.wrap}>
      <span className={s.number}>{minutes > 0 ? minutes : seconds >= 0 ? seconds : 0}</span>
      <span className={s.unit}>{minutes > 0 ? 'м' : 'с'}</span>
      <div id='cont' className={s.cont} data-pct='10'>
        <svg
          className={s.svg}
          id='svg'
          width='32'
          height='32'
          viewBox='0 0 200 200'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            stroke='rgb(206, 213, 216)'
            strokeWidth='18px'
            className={s.bar}
            r='85'
            cx='100'
            cy='100'
            fill='transparent'
            strokeDasharray='534.1'
          />
          <circle
            ref={bar}
            className={s.circle}
            r='85'
            cx='100'
            cy='100'
            fill='transparent'
            strokeDasharray='534.1'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  )
}

export default CookTimer
