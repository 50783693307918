import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import S from './OrderDrawer.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getDishes } from '../../state/menu'
import { resetAnimateOrders } from '../../state/ui'
import { getOrdersCooking, getOrdersReady, getOrdersNew, getOrders } from '../../state/orders'
import cx from 'classnames'
import { TweenMax, Draggable, Ease, Elastic, Power2 } from 'gsap/all'
import { ReactComponent as IcoCooking } from '../../assets/icons/ico-cooking.svg'
import { ReactComponent as IcoReady } from '../../assets/icons/ico-ready.svg'
import OrderHandle from './OrderHandle'
import { Checkout } from '../Checkout'
import { Comment, CommentBtn } from './Comment'
import OrderToggler from '../OrderToggler/OrderToggler'
import { findId, orderBtnText } from '../../utils'
import Item from './Item'
import Transactions from './Transactions'
import { PaymentBtn } from './PaymentBtn'

const open_x = 70
const closed_x = document.getElementsByTagName('body')[0].getBoundingClientRect().right - 38

export default function OrderDrawer({ showOrderDrawer, limitScroll, takeout, setTakeout, ...other }) {
  const orders = useSelector(getOrders)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [showCheckout, setShowCheckout] = useState(false)
  const [comment, setComment] = useState('')
  const [showComment, setShowComment] = useState(false)
  const orderRef = useRef()
  const checkoutRef = useRef()
  const contentRef = useRef()

  function changeOrderState(newstate, click = false) {
    const drawer = orderRef.current
    let currentstate = drawer.classList.contains(S.open)
    // console.log('changeOrderState', 'currentstate =', currentstate, 'newstate =', newstate, 'click =', click)
    if (currentstate && newstate && click) return
    if (currentstate !== newstate) {
      setOpen(newstate)
      switch (newstate) {
        case true:
          limitScroll(contentRef.current)
          break
        case false:
          limitScroll(false)
          contentRef.current.scrollTo(0, 0) // temporary: always scroll to top when closed
        // no default
      }
    } else {
      animateOrder(currentstate) // bounce back
    }
  }

  function animateOrder(newstate) {
    Draggable.get(orderRef.current).disable()
    setTimeout(() => Draggable.get(orderRef.current).enable(), 300)
    TweenMax.to(orderRef.current, 0.3, {
      x: newstate ? open_x : closed_x,
      ease: newstate ? Ease.easeOut : Ease.easeIn,
      roundProps: 'x',
      delay: 0.001
    })
  }

  // Payment Animation
  useEffect(() => {
    if (!showCheckout) {
      const toAnimate = document.getElementsByClassName('animatePayment')
      const containerNew = document.getElementById('orders_new')
      const containerCooking = document.getElementById('orders_cooking')
      /* for (let item of toAnimate) {
        let newItem = item.cloneNode(true)
        newItem.classList.remove('animatePayment')
        if (newItem) container.insertBefore(newItem, container.childNodes[1])
      } */
      /* Array.from(toAnimate).forEach(el => {
        containerCooking.insertBefore(el.parentElement, containerNew.childNodes[1])
      }) */
      for (let item of toAnimate) {
        containerNew.insertBefore(item.parentElement, containerNew.childNodes[1])
      }
      setTimeout(() => {
        TweenMax.staggerFrom(
          toAnimate,
          2,
          {
            onStart: function() {
              containerCooking.insertBefore(this.target.parentElement, containerCooking.childNodes[1])
            },
            top: '-=20',
            ease: Elastic.easeOut.config(1, 0.5),
            onCompleteAll: () => {
              dispatch(resetAnimateOrders())
            }
          },
          -0.5
        )
      }, 500)
    }
  }, [showCheckout])

  // init OrderDrawer
  useLayoutEffect(() => {
    const drawer = orderRef.current
    let switchPoint
    TweenMax.set(drawer, { x: closed_x + 100 })
    Draggable.create(drawer, {
      type: 'x',
      minimumMovement: 4,
      dragClickables: true,
      allowContextMenu: true,
      trigger: '.orderDraggable',
      onDragStart: () => {
        switchPoint = drawer.classList.contains(S.open) ? open_x + 50 : closed_x - 50
      },
      onDragEnd: () => {
        changeOrderState(drawer._gsTransform.x < switchPoint)
      }
    })
  }, [])

  // show and remove OrderDrawer when empty
  let firstrun = useRef('true')
  useEffect(() => {
    const drawer = orderRef.current
    if (showOrderDrawer) {
      drawer.style.visibility = ''
      TweenMax.to(drawer, 0.3, {
        x: closed_x,
        ease: Ease.easeOut
      })
      Draggable.get(drawer).enable()
      Draggable.get(drawer).applyBounds({ minX: open_x, maxX: closed_x + 30 })
    } else {
      if (firstrun.current) {
        firstrun.current = false
        return
      }
      const [timeout, duration] = [400, 500]
      setTimeout(() => {
        setOpen(false)
        limitScroll(false)
        setTimeout(() => {
          drawer.style.visibility = 'hidden'
        }, duration)
      }, timeout)
      Draggable.get(drawer).disable()
      TweenMax.to(drawer, duration / 1000, {
        x: closed_x + 100,
        ease: Ease.easeIn,
        delay: timeout / 1000
      })
    }
  }, [showOrderDrawer, firstrun])

  useEffect(() => {
    if (showOrderDrawer) animateOrder(open)
  }, [open, showOrderDrawer])

  // dynamically change OrderHandle position when viewport height changes
  useEffect(() => {
    setTimeout(() => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`) // fix for 1st render
    }, 100)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
    let history = document.getElementsByClassName(S.orderHistory)[0]
    contentRef.current.addEventListener('scroll', function(e) {
      if (this.scrollHeight - this.scrollTop - this.clientHeight <= 0) {
        history.classList.add(S.noshadow)
      } else {
        if (document.querySelectorAll('.' + S.noshadow).length > 0) history.classList.remove(S.noshadow)
      }
    })
  }, [])

  // orderHistory autoshadow: track scroll height
  useLayoutEffect(() => {
    setTimeout(() => {
      if (contentRef.current.scrollHeight === contentRef.current.clientHeight)
        document.getElementsByClassName(S.orderHistory)[0].classList.add(S.noshadow)
      else document.getElementsByClassName(S.orderHistory)[0].classList.remove(S.noshadow)
    }, 200)
  }, [orders])

  return (
    <>
      <div id={S.orderDrawer} className={open ? S.open : S.closed} ref={orderRef}>
        <OrderContent
          state={open}
          {...{
            comment,
            setShowComment,
            setShowCheckout,
            takeout,
            setTakeout,
            orderRef,
            showOrderDrawer,
            changeOrderState
          }}
          ref={contentRef}
          {...other}
        />
      </div>
      <div ref={checkoutRef} id='checkoutRef'>
        <Checkout
          {...{
            contentRef,
            showCheckout,
            setShowCheckout,
            limitScroll,
            comment,
            setComment,
            setShowComment,
            takeout
          }}
          orderDrawer={orderRef.current}
        />
      </div>
      <Comment {...{ comment, setComment, showComment, setShowComment, limitScroll }} />
      <Shader open={open} changeOrderState={changeOrderState} />
    </>
  )
}

const OrderContent = React.forwardRef(
  (
    {
      open,
      state,
      setShowCheckout,
      comment,
      setShowComment,
      venue,
      takeout,
      setTakeout,
      orderRef,
      showOrderDrawer,
      changeOrderState,
      ...other
    },
    contentRef
  ) => {
    const dishes = useSelector(getDishes)
    const ordersNew = useSelector(getOrdersNew)
    const ordersCooking = useSelector(getOrdersCooking)
    const ordersReady = useSelector(getOrdersReady)

    const [options, setOptions] = useState(false)
    const manualDrag = e => Draggable.get(orderRef.current).startDrag(e)

    return (
      <div onClick={() => showOrderDrawer && changeOrderState(true, true)}>
        <div id={S.orderContent} className={cx(state ? S.open : S.closed, 'orderDraggable')} ref={contentRef}>
          {/**
        |--------------------------------------------------
        | NEW
        |--------------------------------------------------
        */}
          <div id='orders_new' className={`${S.itemsContainer} ${S.new}`}>
            <div id={S.togglerWrapper}>
              <OrderToggler {...{ takeout, setTakeout }} />
            </div>
            {ordersNew.length ? (
              <>
                {ordersNew.map(o => (
                  <Item
                    state={state}
                    setOptions={setOptions}
                    options={options}
                    dish={findId(o.dishId, dishes)}
                    key={`new-${o.tempId}`}
                    id={o.tempId}
                    type={'new'}
                    {...other}
                  />
                ))}
              </>
            ) : null}
            <CommentBtn {...{ comment, setShowComment }} />

            <PaymentBtn actions={[setShowCheckout]} {...{ takeout }} />
          </div>
          {/**
        |--------------------------------------------------
        | IN PROGRESS
        |--------------------------------------------------
        */}
          {ordersCooking.length ? (
            <div id='orders_cooking' className={`${S.itemsContainer} ${S.cooking}`}>
              <div className={`${S.subhead} ${S.cooking}`}>
                <IcoCooking />
                <span>Готовится</span>
              </div>
              {ordersCooking
                .filter(f => !f.takeout)
                .map(o => (
                  <Item
                    orderId={o.id}
                    state={state}
                    setOptions={setOptions}
                    options={options}
                    dish={findId(o.dishId, dishes)}
                    key={`order-${o.id}`}
                    type={'cooking'}
                  />
                ))}
              <Transactions {...{ state, setOptions, options }} mode='cooking' />
            </div>
          ) : null}
          {/**</div>
        |--------------------------------------------------
        | READY
        |--------------------------------------------------
        */}
          {ordersReady.length ? (
            <div className={`${S.itemsContainer} ${S.ready}`}>
              <div className={`${S.subhead} ${S.ready}`}>
                <IcoReady />
                <span>Готово</span>
              </div>
              {ordersReady
                .filter(f => !f.takeout)
                .map(o => (
                  <Item
                    state={state}
                    setOptions={setOptions}
                    options={options}
                    dish={findId(o.dishId, dishes)}
                    key={`order-${o.id}`}
                    type={'ready'}
                  />
                ))}
              <Transactions {...{ state, setOptions, options }} mode='ready' />
            </div>
          ) : null}
        </div>
        <OrderHandle {...{ manualDrag, state, takeout }} />
        <div className={S.orderHistory}>ИСТОРИЯ ЗАКАЗОВ</div>
      </div>
    )
  }
)

const Shader = ({ open, changeOrderState }) => {
  const [opn, setOpn] = useState(false)
  useEffect(() => {
    if (open && !opn) {
      TweenMax.to(`#${S.shader}`, 0.3, {
        visibility: 'visible',
        autoAlpha: 1,
        ease: Power2.easeOut,
        onComplete: () => {
          setOpn(true)
        }
      })
    } else {
      if (opn) {
        TweenMax.to(`#${S.shader}`, 0.3, {
          autoAlpha: 0,
          visibility: 'hidden',
          ease: Power2.easeOut,
          onComplete: () => {
            setOpn(false)
          }
        })
      }
    }
  }, [open])
  return <div id={S.shader} className='OrderDrawerShader' onClick={() => changeOrderState(false)} />
}
