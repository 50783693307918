import { FETCH_VENUES } from './types'

// TODO: maybe convert to object with id's {(:id): {}}
/* State Shape
[

]
*/

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_VENUES: {
      const { venues } = action.payload
      return venues
    }

    default:
      return state
  }
}
