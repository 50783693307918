import React, { useRef, useLayoutEffect } from 'react'
import { TweenMax, TimelineMax, Power3 } from 'gsap'
import { Link } from '@reach/router'
import s from './SubcatPanel.module.css'
import classnames from 'classnames'

export default function SubcatPanel({ showOrderDrawer, setShowSubcatPanel, catCardCoord }) {
  const boxRef = useRef()
  const wrapperRef = useRef()
  const tlRef = useRef()
  function animate() {
    tlRef.current.reverse()
  }
  useLayoutEffect(() => {
    tlRef.current = new TimelineMax({
      onReverseComplete: () => setShowSubcatPanel(false)
    })
    tlRef.current.add(
      TweenMax.from(boxRef.current, 0.3, {
        top: catCardCoord.top - catCardCoord.width / 2,
        left: catCardCoord.left - catCardCoord.height / 2,
        transform: 'scale(.5)'
        // width: catCardCoord.width,
        // height: catCardCoord.height
        // ease: Power3.easeOut
      }),
      TweenMax.from(
        wrapperRef.current,
        0.3,
        {
          opacity: 0
        },
        0
      )
    )
  })

  return (
    <div className={s.wrapper} onClick={e => animate()} ref={wrapperRef}>
      <div className={classnames(s.box, showOrderDrawer ? s.drawerOpen : null)} ref={boxRef}>
        <div className={s.title}>
          <img className={s.icon} src='img/ico-beverages.svg' alt='' />
          <div className={s.header}>Напитки</div>
        </div>
        <div className={s.row}>
          <div className={s.column}>
            <Link
              to='richmenu/200'
              onClick={e => e.stopPropagation() || TweenMax.to(e.target, 0.3, { transform: 'scale(2)' })}
            >
              <div className={s.card} style={{ backgroundImage: 'url(img/img-tea@2x.png)' }}>
                <div style={{ textAlign: 'right', color: 'white' }}>Чай</div>
              </div>
            </Link>
          </div>
          <div className={s.column}>
            <Link
              to='richmenu/100'
              onClick={e => e.stopPropagation() || TweenMax.to(e.target, 0.3, { transform: 'scale(2)' })}
            >
              <div className={s.card} style={{ backgroundImage: 'url(img/img-coffee@2x.png)' }}>
                <div>Кофе</div>
              </div>
            </Link>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.column}>
            <Link
              to='richmenu/400'
              onClick={e => e.stopPropagation() || TweenMax.to(e.target, 0.3, { transform: 'scale(2)' })}
            >
              <div className={s.card} style={{ backgroundImage: 'url(img/img-water@2x.png)' }}>
                <div style={{ textAlign: 'right' }}>Вода</div>
              </div>
            </Link>
          </div>
          <div className={s.column}>
            <Link
              to='richmenu/300'
              onClick={e => e.stopPropagation() || TweenMax.to(e.target, 0.3, { transform: 'scale(2)' })}
            >
              <div className={s.card} style={{ backgroundImage: 'url(img/img-juice@2x.png)' }} />
              <div />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
