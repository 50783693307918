import {
  FETCH_PAID_ORDERS,
  ADD_NEW_ORDER,
  REMOVE_NEW_ORDER,
  PROCESS_ORDER_FROM_WEBSOCKET,
  SET_PAYMENT_TYPE,
  SET_TAKEOUT
} from './types'

export const fetchPaidOrders = orders => ({
  type: FETCH_PAID_ORDERS,
  payload: { orders }
})

export const addNewOrder = dish => ({
  type: ADD_NEW_ORDER,
  payload: { dish }
})

export const removeNewOrder = tempId => ({
  type: REMOVE_NEW_ORDER,
  payload: { tempId }
})

export const processWebsocketOrder = msg => ({
  type: PROCESS_ORDER_FROM_WEBSOCKET,
  payload: { msg }
})

export const setPaymentType = type => ({
  type: SET_PAYMENT_TYPE,
  payload: { type }
})

export const setTakeout = type => ({
  type: SET_TAKEOUT,
  payload: { type }
})
