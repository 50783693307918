const settings = {
  production: {
    API_URL: 'https://api.khlebniy.com.ua/v3',
    WS_URL: 'wss://api.khlebniy.com.ua/cable'
  },
  staging: {
    API_URL: 'https://api.khlebniy.com.ua/v3',
    WS_URL: 'wss://api.khlebniy.com.ua/cable'
  },
  develop: {
    API_URL: 'https://devapi.khlebniy.com.ua/v3',
    WS_URL: 'wss://devapi.khlebniy.com.ua/cable'
  },
  localdev: {
    API_URL: 'http://localhost:4444',
    WS_URL: 'ws://localhost:4444/cable'
  }
}

//window.mode
// export const config = process.env.REACT_APP_DEV ? dev : prod

export const config = settings[window.mode]
