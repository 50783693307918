import { ADD_CATS, ADD_SUBCATS, ADD_DISHES, TOGGLE_STOPLIST } from './types'

/* State Shape
{
  cats: [],
  subcats: [],
  dishes: []
}
*/

const initialState = {
  cats: [],
  subcats: [],
  dishes: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_CATS: {
      const { cats } = action.payload
      return {
        ...state,
        cats: cats
      }
    }

    case ADD_SUBCATS: {
      const { subcats } = action.payload
      return {
        ...state,
        subcats: subcats
      }
    }

    case ADD_DISHES: {
      const { dishes } = action.payload
      return {
        ...state,
        dishes: dishes
      }
    }

    case TOGGLE_STOPLIST: {
      const { id, stoplist } = action.payload
      let dishesUpdated

      if (stoplist === true) {
        // add to stoplist
        dishesUpdated = state.dishes.map(x => (x.id === id ? { ...x, stoplist: true } : x))
      } else {
        dishesUpdated = state.dishes.map(x => (x.id === id ? { ...x, stoplist: false } : x))
      }

      return {
        ...state,
        dishes: dishesUpdated
      }
    }

    default:
      return state
  }
}
