import React from 'react'
import { TweenMax, Power3 } from 'gsap/all'
import S from './SubcatSelector.module.css'

export const SubcatSelector = ({
  catId,
  subcats,
  children,
  scrollToCat,
  scrollToSubcat,
  subcatSelector,
  setSubcatSelector
}) => {
  const sbct = subcats.filter(subcat => subcat.catId === catId)
  const handleRef = React.useRef()
  const containerRef = React.useRef()
  const listRef = React.useRef()

  const subcatRows = document.getElementsByClassName(`${S.subcatRow} ${catId}`)

  for (let i = 0; i < subcatRows.length; i++) {
    TweenMax.set(subcatRows[i], { top: 0, autoAlpha: 0 })
  }
  document.onscroll = () => {
    setSubcatSelector(false)
  }
  document.ondragstart = () => {
    setSubcatSelector(false)
  }
  const clickDropdown = () => {
    if (window.scrollY < document.getElementById(`cat${catId}`).offsetTop - 136) {
      scrollToCat(catId)
      setTimeout(() => setSubcatSelector(!subcatSelector), 100)
    } else {
      setSubcatSelector(!subcatSelector)
    }
  }
  const clickSelector = (e, id) => {
    e.stopPropagation()
    scrollToSubcat(id, catId)
    setSubcatSelector(false)
  }

  React.useEffect(() => {
    if (!subcatSelector && containerRef.current && listRef.current) {
      TweenMax.set(containerRef.current, {
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.25);',
        background: 'white'
      })
      TweenMax.to(listRef.current, 0.3, { height: 48, autoAlpha: 0, ease: Power3.easeOut })
      for (let i = 0; i < subcatRows.length; i++) {
        TweenMax.to(subcatRows[i], 0.3, {
          top: 48,
          autoAlpha: 0,
          ease: Power3.easeOut
        })
      }
    }
    if (subcatSelector && containerRef.current && listRef.current) {
      TweenMax.set(containerRef.current, { boxShadow: 'none', background: 'none' })
      TweenMax.to(listRef.current, 0.3, {
        height: 68 + subcatRows.length * 44,
        autoAlpha: 1,
        ease: Power3.easeOut
      })
      for (let i = 0; i < subcatRows.length; i++) {
        TweenMax.to(subcatRows[i], 0.3, {
          top: 48 + i * 44,
          autoAlpha: 1,
          ease: Power3.easeOut
        })
      }
    }
  }, [subcatSelector])

  return (
    <div className={S.container} ref={containerRef} onClick={sbct.length > 1 ? clickDropdown : null}>
      <span>{children}</span>
      {sbct.length > 1 && (
        <>
          <ul className={S.subcatList} ref={listRef}>
            {sbct.map(subcat => (
              <li className={`${S.subcatRow} ${catId}`} key={subcat.id} onClick={e => clickSelector(e, subcat.id)}>
                <span>{subcat.name}</span>
              </li>
            ))}
            <div className={S.handle} />
          </ul>
          {!subcatSelector && <div className={S.handle} ref={handleRef} />}
        </>
      )}
    </div>
  )
}
