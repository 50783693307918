import React, { useState, useEffect } from 'react'
import s from './QRscan.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { unload } from '../../state/loader'
import { setVenueId, getVenueId, setTableNum, getTableNum } from '../../state/location'

export const QRUrl = props => {
  const url = window.location.pathname.replace(/\//g, '')

  return url ? <UrlSwitch url={url} {...props} /> : null
}

const UrlSwitch = ({ url, setTakeout }) => {
  const dispatch = useDispatch()
  const [display, setDisplay] = useState(true)
  const [confirmation, setConfirmation] = useState(true)
  // let venueId = 0
  // let tableNum = 0

  const onClose = () => {
    window.history.replaceState(null, null, '/')
    setDisplay(false)
  }

  const type = url[0]

  useEffect(() => {
    // TODO: DRY, refactor
    switch (type) {
      case 't':
        if (url.length === 5) {
          const venueId = parseInt(url.slice(1, 3))
          const tableNum = parseInt(url.slice(3, 5))
          dispatch(setVenueId(venueId))
          dispatch(setTableNum(tableNum))
          dispatch(unload('VenueSelect'))
          setTakeout(false)
          setConfirmation(true)
        } else {
          console.log('error: wrong venue or table number')
        }
        break
      case 'v':
        if (url.length === 3) {
          const venueId = parseInt(url.slice(1, 3))
          dispatch(setVenueId(venueId))
          dispatch(setTakeout(true))
          dispatch(unload('VenueSelect'))
          onClose()
        } else {
          console.log('error: wrong venue or table number')
        }
        break
      default:
        console.log('default')
    }
  }, [])

  return display && confirmation ? <Confirmation {...{ onClose }} /> : null
}

const Confirmation = ({ onClose }) => {
  const tableNum = useSelector(getTableNum)

  return (
    <div className={s.wrapper}>
      <nav className={s.navbar}>
        <div className={s.icon} onClick={onClose}>
          <img src='/img/close.svg' alt='' />
        </div>
        <div>Сканировать QR код</div>
        <div className={s.right}>&nbsp;</div>
      </nav>

      <div className={s.confirmation}>
        Сканирование успешно
        <br />
        <br />
        {tableNum ? <Table {...{ tableNum }} /> : null}
        <div className={s.done} onClick={onClose}>
          Готово
        </div>
      </div>
    </div>
  )
}

const Table = ({ tableNum }) => (
  <>
    Ваш столик:
    <br />
    <div className={s.tableNum}>{tableNum}</div>
  </>
)

// const Venue = ({ venueId }) => (
//   <>
//     Заказ с собой.
//     <br />
//     {/* <div className={s.tableNum}>{venueId}</div> */}
//   </>
// )
