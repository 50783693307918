import React from 'react'
import s from './DishTimer.module.css'
import { ReactComponent as TimerIcon } from '../../assets/icons/timer15.svg'

export default function DishTimer({ customStyle, darkTheme = true }) {
  return (
    <div
      className={s.box}
      style={{
        ...customStyle,
        border: darkTheme ? '1px solid #ffffff' : '1px solid #000000',
        background: darkTheme ? 'rgba(0, 0, 0, 0.25)' : 'transparent'
      }}
    >
      <TimerIcon className={s.timer} fill={darkTheme ? 'white' : 'black'} />
      <span className={s.text} style={{ color: darkTheme ? 'white' : 'black' }}>
        15 m
      </span>
    </div>
  )
}
