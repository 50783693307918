import { LOAD_COMPONENT, UNLOAD_COMPONENT } from './types'

export const load = name => ({
  type: LOAD_COMPONENT,
  payload: { name }
})

export const unload = name => ({
  type: UNLOAD_COMPONENT,
  payload: { name }
})
