import React from 'react'
import { ReactComponent as CatBeverages } from '../../assets/icons/cat-beverages.svg'
import { ReactComponent as CatBreakfast } from '../../assets/icons/cat-breakfast.svg'
import { ReactComponent as CatBread } from '../../assets/icons/cat-bread.svg'
import { ReactComponent as CatBakery } from '../../assets/icons/cat-bakery.svg'
import { ReactComponent as CatSalad } from '../../assets/icons/cat-salad.svg'
import { ReactComponent as CatBurger } from '../../assets/icons/cat-burger.svg'

export default function CatIcons({ icon, size = '24', color }) {
  const attributes = {
    width: size,
    height: size,
    fill: color,
    className: 'cat-icon'
  }

  switch (icon) {
    case 'beverages':
      return <CatBeverages {...attributes} />
    case 'breakfast':
      return <CatBreakfast {...attributes} />
    case 'burger':
      return <CatBurger {...attributes} />
    case 'salad':
      return <CatSalad {...attributes} />
    case 'bakery':
      return <CatBakery {...attributes} />
    case 'bread':
      return <CatBread {...attributes} />
    default:
      return <CatBeverages {...attributes} />
  }
}
