import React, { Fragment } from 'react'
import s from './FavIcon.module.css'
import { useSelector } from 'react-redux'

export default function FavButton({ dishId, style }) {
  const favorites = useSelector(state => state.favorites)
  const liked = favorites.includes(dishId) ? true : false

  return <Fragment>{liked ? <img src='/img/heart.svg' {...{ style }} className={s.image} alt='' /> : null}</Fragment>
}
