import React from 'react'
import CatIcons from './shared/CatIcons'
import { TweenMax, Back } from 'gsap'

const CatTab = ({ cat, scrollToCat, catsInView }) => {
  const [isAct, setAct] = React.useState(false)
  const tapzoneRef = React.useRef()
  const btnRef = React.createRef()

  const animationUp = ref =>
    TweenMax.to(ref, 0.2, {
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      width: '64px',
      height: '64px',
      backgroundColor: 'white',
      ease: Back.easeOut.config(2),
      onComplete: setAct(false)
    })

  const animationDown = ref =>
    TweenMax.to(ref, 0.2, {
      boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
      width: '58px',
      height: '58px',
      backgroundColor: 'red',
      onComplete: setAct(true)
    })

  const clickHandler = () => {
    if (cat.id !== catsInView[0]) {
      animationDown(tapzoneRef.current)
      scrollToCat(cat.id)
    }
  }

  React.useEffect(() => {
    if (cat.id === catsInView[0] && tapzoneRef.current && btnRef.current) {
      btnRef.current.scrollIntoView({ inline: 'center' })
      animationDown(tapzoneRef.current)
    } else {
      animationUp(tapzoneRef.current)
    }
  }, [catsInView])

  return (
    <div
      ref={btnRef}
      onClick={clickHandler}
      key={`catnav${cat.id}`}
      style={{
        display: 'inline-block',
        width: '72px',
        height: '72px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '72px',
          height: '72px'
        }}
      >
        <div
          ref={tapzoneRef}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '64px',
            height: '64px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '50px'
          }}
        >
          <CatIcons icon={cat.icon} size='28' color={isAct ? 'white' : 'black'} />
        </div>
      </div>
    </div>
  )
}
export default CatTab
