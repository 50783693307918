import React, { useState, useEffect } from 'react'
import s from './QRscan.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { unload } from '../../state/loader'
import { setVenueId, getVenueId, setTableNum, getTableNum } from '../../state/location'
import QrReader from 'react-qr-reader'
import { ReactComponent as QRIcon } from '../../assets/icons/qr-code.svg'

export const QRScan = () => {
  const dispatch = useDispatch()
  const [code, setCode] = useState(false)
  const [manual, setManual] = useState('')
  const [checksumError, setChecksumError] = useState(false)

  useEffect(() => {
    if (manual.length === 5) {
      const checksumDigit = parseInt(manual.slice(0, 1))
      const checksum = manual
        .slice(1, 5)
        .split('')
        .reduce((acc, n) => {
          acc += parseInt(n)
          return acc
        }, 0)

      if (checksumDigit === checksum % 10) {
        const venueId = parseInt(manual.slice(1, 3))
        const tableNum = parseInt(manual.slice(3, 5))
        dispatch(setVenueId(venueId))
        dispatch(setTableNum(tableNum))
        setCode(true)
      } else {
        setChecksumError(true)
      }
    }
  }, [manual])

  const handleScan = data => {
    if (data) {
      const { status, venueId, tableNum } = urlParser(data)
      if (status === 'success') {
        dispatch(setVenueId(venueId))
        dispatch(setTableNum(tableNum))
        setCode(true)
      }
    }
  }

  const handleError = err => {
    console.error(err)
  }

  return (
    <div className={s.wrapper}>
      <nav className={s.navbar}>
        <div className={s.icon} onClick={() => dispatch(unload('QRScan'))}>
          <img src='/img/close.svg' alt='' />
        </div>
        <div>Сканировать QR код</div>
        <div className={s.right}>&nbsp;</div>
      </nav>
      {code ? (
        <Confirmation />
      ) : (
        <ScanView onError={handleError} onScan={handleScan} {...{ manual, setManual, checksumError }} />
      )}
    </div>
  )
}

const ScanView = ({ onError, onScan, manual, setManual, checksumError }) => (
  <>
    <div className={s.manual}>
      <form>
        <input
          type='tel'
          value={manual}
          onChange={e => setManual(e.target.value)}
          placeholder='Или введите код вручную'
        />
        {checksumError ? <span className={s.error}>Ошибка. Неверный код</span> : null}
      </form>
    </div>

    <QrReader delay={900} onError={onError} onScan={onScan} className={s.reader} />

    <div className={s.help}>
      Поместите изображение кода
      <br />
      <QRIcon width='70' height='70' />
      <br />в рамки видоискателя - код будет считан автоматически
    </div>
  </>
)

const Confirmation = () => {
  const dispatch = useDispatch()
  const tableNum = useSelector(getTableNum)

  return (
    <div className={s.confirmation}>
      Сканирование успешно
      <br />
      <br />
      Ваш столик:
      <br />
      <div className={s.tableNum}>{tableNum}</div>
      <div className={s.done} onClick={() => dispatch(unload('QRScan'))}>
        Готово
      </div>
    </div>
  )
}

const urlParser = url => {
  const urlCode = url.split('/').slice(-1)[0]
  const type = urlCode[0]

  switch (type) {
    case 't':
      if (urlCode.length === 5) {
        const venueId = parseInt(urlCode.slice(1, 3))
        const tableNum = parseInt(urlCode.slice(3, 5))
        return {
          status: 'success',
          venue: venueId,
          table: tableNum
        }
      } else {
        console.log('error: wrong venue or table number')
        return { status: 'error' }
      }
      break
    default:
      console.log('default')
      return { status: 'error' }
  }
}
