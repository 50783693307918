import { SET_NOT_FIRST_TIME } from './types'

/* State Shape
{
  id: int,
  firstTime: true | null
}
*/

const initialState = {
  id: 1,
  firstTime: false // false for dev
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_NOT_FIRST_TIME: {
      return {
        ...state,
        firstTime: null
      }
    }

    default:
      return state
  }
}
