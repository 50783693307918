import React, { useState, useEffect, useRef } from 'react'
import s from './GetContactInfo.module.css'
import { useDispatch } from 'react-redux'
import { unload } from '../state/loader'
import { setNotFirstTime } from '../state/user'

export const GetContactInfo = () => {
  const dispatch = useDispatch()
  const [text, setText] = useState('')

  return (
    <div className={s.wrapper}>
      <div>
        <h2>Номер телефона</h2>
        <input
          type='tel'
          autoComplete='tel'
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder='380671231212'
        />
        <button
          className={s.button}
          onClick={() => {
            dispatch(unload('GetContactInfo'))
            dispatch(setNotFirstTime())
          }}
        >
          Далее
        </button>
        <p>Мы научимся отправлять уведомления через Телеграм, ФБ мессенджер и WhatsApp чуть позже ;)</p>
      </div>
    </div>
  )
}
