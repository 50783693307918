import { SET_VENUE_ID, SET_TABLE_NUM, SET_VENUE_OPEN } from './types'

export const setVenueId = id => ({
  type: SET_VENUE_ID,
  payload: { id }
})

export const setTableNum = num => ({
  type: SET_TABLE_NUM,
  payload: { num }
})

export const setVenueOpen = status => ({
  type: SET_VENUE_OPEN,
  payload: { status }
})
