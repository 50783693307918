import { ANIMATE_ORDERS, RESET_ANIMATE_ORDERS } from './types'

export const animateOrders = msg => ({
  type: ANIMATE_ORDERS,
  payload: { msg }
})

export const resetAnimateOrders = () => ({
  type: RESET_ANIMATE_ORDERS
})
