import React, { useState, useEffect } from 'react'
import s from './Checkout.module.css'
import Navbar from '../shared/Navbar'

export const Confirmation = ({ setShowCheckout, paymentTakeout, paymentTakeoutNum, table }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.confirmation}>
        <Navbar icon='/img/close-black.svg' onClick={() => setShowCheckout(false)}>
          {paymentTakeout ? 'Оплата успешна' : 'Спасибо за заказ'}
        </Navbar>

        {paymentTakeout ? <TakeoutView {...{ paymentTakeoutNum }} /> : <TableView {...{ table }} />}

        <div className={s.closeBtn} onClick={() => setShowCheckout(false)}>
          Закрыть
        </div>
      </div>
    </div>
  )
}

const TakeoutView = ({ paymentTakeoutNum }) => (
  <>
    <p>Номер заказа:</p>
    <h2>{paymentTakeoutNum}</h2>
    <p>Мы пришлём уведомление, когда заказ будет готов.</p>
    <p>Ориентировочное время 15 минут.</p>
  </>
)

const TableView = ({ table }) => (
  <>
    <p>Уже готовим!</p>
    <p>Мы принесём заказ за столик номер:</p>
    <h2>{table}</h2>
  </>
)
