import { propEq, find, isEmpty } from 'ramda'
import { TweenMax, Ease } from 'gsap'

export const findId = (id, arr) => find(propEq('id', id), arr)

export function money(amount) {
  const currency = new Intl.NumberFormat('en-IN', {
    style: 'decimal',
    maximumFractionDigits: 2
  }).format(amount / 100)
  return currency
}

export function animateOrder(icon) {
  const landingduration = 800
  // fixme: we need to replace this line
  const neworder = document.getElementById('orders_new').children[1].firstChild
  const neworderbox = neworder.getBoundingClientRect()
  const iconbox = icon.getBoundingClientRect()
  // start landing animation
  neworder.myClassToggle('animate')
  setTimeout(() => {
    neworder.myClassToggle('animate')
  }, landingduration)
  TweenMax.set(neworder, { x: iconbox.x - neworderbox.x, y: iconbox.y - neworderbox.y })
  TweenMax.set(neworder.parentElement, { height: 0 })
  TweenMax.to(neworder, landingduration / 1000, {
    x: 0,
    y: 0,
    ease: Ease.easeOut
  })
  TweenMax.to(neworder.parentElement, landingduration / 1000, { height: '40px' })
}

export const isVenueOpen = venue => {
  let result = false

  if (venue) {
    const daysOfWeek = {
      0: 'sun',
      1: 'mon',
      2: 'tue',
      3: 'wed',
      4: 'thu',
      5: 'fri',
      6: 'sat'
    }

    const workingHours = venue.workingHours
    const now = Date.now()
    // console.log('now: ', now, new Date(now))
    const dayOfWeek = daysOfWeek[new Date(now).getDay()]
    const [openTime, closeTime] = workingHours[dayOfWeek]
    const open = new Date(now).setHours(...openTime.split(':'), 0)
    // console.log('open: ', open, new Date(open))
    const close = new Date(now).setHours(...closeTime.split(':'), 0)
    result = open < now && now < close
  }

  return result
}
