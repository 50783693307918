import React from 'react'
import s from './VenueSelect.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { unload } from '../../state/loader'
import { getVenues } from '../../state/venues'
import { setVenueId, getVenueId, setVenueOpen } from '../../state/location'
import Navbar from '../shared/Navbar'
import { VenueView } from './VenueView'

export const VenueSelect = () => {
  const dispatch = useDispatch()
  const venues = useSelector(getVenues)
  const activeVenue = useSelector(getVenueId)

  const handleSetVenue = venue => () => {
    // после выбора заведения добавить его Id в стейт
    dispatch(setVenueId(venue.id))
  }

  const handleClose = () => {
    dispatch(unload('VenueSelect'))
  }

  return (
    <div className={s.wrapper}>
      <Navbar icon='/img/close-black.svg' onClick={handleClose}>
        Рестораны
      </Navbar>

      {venues.map(venue => (
        <VenueView {...{ venue, handleSetVenue, activeVenue }} key={venue.id} />
      ))}
    </div>
  )
}
